import React, { Component } from "react";
import _ from "lodash";
import Spinner from "react-spinkit";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Fade from "react-reveal/Fade";
import Validator from "validator";
import {
  countriesOptionsSelector,
  productsHashSelector,
} from "../../selectors";
import Money from "../elements/Money";
import FormButton from "../elements/FormButton";
import { withTranslation } from "react-i18next";
import { makeRequest } from "../../utils/request";

const initialState = {
  internationalNumber: "",
  sanitizedNumber: "",
  country: "",
  product: "",
  amount: "",
  accountId: "",
  accountIdLabel: "",
  isVoucher: false,
  products: [],
  amounts: [],
  errors: {},
  loading: false,
  fetchingMsisdn: false,
  fetched: false,
  msisdn: {},
};

class InternationalTopupForm extends Component {
  state = initialState;

  componentDidMount() {
    this.phone.focus();
  }

  clearForm = (e) => {
    e.preventDefault();
    this.setState({ ...initialState });
    this.phone.focus();
  };

  onCountryChange = (e) => {
    const countryId = e.target.value;
    this.setState({
      country: countryId,
      products: _.orderBy(
        _.filter(this.props.products, { CountryID: parseInt(countryId, 10) }),
        ["Carrier"]
      ),
      product: "",
      amounts: [],
      amount: "",
    });
  };

  onProductChange = (e) => {
    const productId = e.target.value;
    const product = this.props.products[productId];
    this.setState({
      product: productId,
      amounts: this.props.products[productId].Amounts.split(","),
      isVoucher: product.Type === "VOUCHER",
      accountIdLabel: product.AdditionalInformation,
    });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const isValid = await this.validate();
    if (!isValid) return;
    const product = this.props.products[this.state.product];
    this.props.submit({
      Amount: parseFloat(this.state.amount),
      InternationalNumber: this.state.sanitizedNumber,
      Country: product.Country,
      Carrier: product.Carrier,
      CCID: Number(this.state.product),
      Fee: product.Fee,
      AccountID: this.state.accountId,
    });
  };

  fetchMsisdn = async (e) => {
    e.preventDefault();
    if (!this.state.internationalNumber) {
      this.setState({ errors: { internationalNumber: "errors.empty" } });
      return;
    }
    this.setState({ fetchingMsisdn: true });

    try {
      const res = await makeRequest(
        "get",
        `/api/msisdn/${this.state.internationalNumber}`
      );
      this.setState({ msisdn: res.msisdn });
      if (res.msisdn.Country) {
        const country = _.find(this.props.countries, {
          Description: res.msisdn.Country,
        });
        const products = _.orderBy(
          _.filter(this.props.products, (item) => {
            return (
              item.CountryID === Number(country.CountryID) &&
              (item.Type === "VOUCHER" ||
                item.Carrier.startsWith(res.msisdn.Operator.split(" ")[0]))
            );
          }),
          ["Carrier"]
        );
        if (products.length === 1)
          this.onProductChange({ target: { value: products[0].ProductID } });
        this.setState({
          fetchingMsisdn: false,
          fetched: true,
          country: country.CountryID,
          products,
        });
      } else {
        this.setState({ fetchingMsisdn: false, fetched: true });
      }
    } catch (err) {
      this.setState({
        fetchingMsisdn: false,
        errors: err.errors,
      });
    }
  };

  validate = () => {
    return new Promise((resolve) => {
      const errors = {};

      const internationalNumber = this.state.internationalNumber;

      const country = _.find(this.props.countries, {
        CountryID: Number(this.state.country),
      });
      const sanitizedNumber = internationalNumber.substr(
        internationalNumber.length - country.NumLength
      );
      if (
        !Validator.isMobilePhone(sanitizedNumber, "any") ||
        sanitizedNumber.length !== country.NumLength
      )
        errors.internationalNumber = "errors.notAPhoneNumber";

      if (!this.state.country) errors.country = "errors.empty";
      if (!this.state.product) errors.product = "errors.empty";
      if (parseFloat(this.state.product) <= 0)
        errors.amount = "errors.zeroAmount";

      if (this.state.isVoucher && !this.state.accountId)
        errors.accountId = "errors.empty";

      this.setState({ errors, sanitizedNumber }, () => {
        resolve(Object.values(errors).length === 0);
      });
    });
  };

  render() {
    return (
      <div>
        <form onSubmit={this.fetchMsisdn} noValidate>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="internationalNumber">
                  {this.props.t("labels.internationalNumber")}
                </label>
                <div className="input-group input-group-lg">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-phone" />
                    </span>
                  </div>
                  <input
                    type="tel"
                    id="internationalNumber"
                    name="internationalNumber"
                    placeholder="country code + number"
                    ref={(input) => (this.phone = input)}
                    className={
                      this.state.errors.internationalNumber
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    onChange={(e) =>
                      this.setState({ internationalNumber: e.target.value })
                    }
                    value={this.state.internationalNumber}
                    disabled={this.state.fetchingMsisdn || this.state.fetched}
                  />
                  <div className="input-group-append">
                    <button
                      className={
                        this.state.fetched
                          ? "btn btn-success"
                          : "btn btn-primary"
                      }
                      disabled={this.state.fetchingMsisdn || this.state.fetched}
                    >
                      {this.state.fetchingMsisdn && (
                        <Spinner name="circle" color="white" fadeIn="none" />
                      )}
                      {!this.state.fetchingMsisdn && !this.state.fetched && (
                        <i className="fas fa-arrow-right" />
                      )}
                      {!this.state.fetchingMsisdn && this.state.fetched && (
                        <i className="fas fa-check" />
                      )}
                    </button>
                    {!this.state.fetchingMsisdn && this.state.fetched && (
                      <button
                        onClick={this.clearForm}
                        className="btn btn-danger"
                      >
                        <i className="fas fa-times-circle" />
                      </button>
                    )}
                  </div>
                  <div className="invalid-feedback">
                    {this.props.t(this.state.errors.internationalNumber)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

        {this.state.fetched && (
          <form onSubmit={this.onSubmit}>
            <div className="row">
              <div className="col-lg-6 col-sm-6">
                <div className="form-group">
                  <label htmlFor="country">
                    {this.props.t("labels.country")}
                  </label>
                  {!this.state.msisdn.Country ? (
                    <select
                      id="country"
                      value={this.state.country}
                      onChange={this.onCountryChange}
                      className="form-control"
                    >
                      <option value="">
                        {this.props.t("labels.selectCountry")}
                      </option>
                      {this.props.countries.map((country) => (
                        <option
                          key={country.CountryID}
                          value={country.CountryID}
                        >
                          {country.Description}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <input
                      type="text"
                      ref={(input) => (this.phone = input)}
                      className="form-control"
                      value={this.state.msisdn.Country}
                      disabled
                    />
                  )}
                </div>
              </div>
              <div className="col-lg-6 col-sm-6">
                {this.state.country && (
                  <Fade>
                    <div className="form-group">
                      <label htmlFor="product">
                        {this.props.t("labels.carrier")}
                      </label>
                      <select
                        id="product"
                        value={this.state.product}
                        onChange={this.onProductChange}
                        className="form-control"
                      >
                        <option value="">
                          {this.props.t("labels.selectCarrier")}
                        </option>
                        {this.state.products.map((product) => (
                          <option
                            key={product.ProductID}
                            value={product.ProductID}
                          >
                            {product.Carrier}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Fade>
                )}
              </div>
            </div>

            {this.state.isVoucher && (
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <label htmlFor="accountId">
                      {this.state.accountIdLabel}
                    </label>
                    <input
                      type="text"
                      id="accountId"
                      name="accountId"
                      className={
                        this.state.errors.accountId
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      onChange={(e) =>
                        this.setState({ accountId: e.target.value })
                      }
                      value={this.state.accountId}
                      disabled={this.state.loading}
                    />
                    <div className="invalid-feedback form-text">
                      {this.props.t(this.state.errors.accountId)}
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="row">
              {this.state.amounts.map((amount) => (
                <div className="col" key={amount}>
                  <div
                    className={
                      amount === this.state.amount
                        ? "card bg-success m-2"
                        : "card m-2"
                    }
                    onClick={() => this.setState({ amount })}
                    role="button"
                  >
                    <div className="card-body d-flex align-items-center justify-content-center">
                      <div
                        className={
                          amount === this.state.amount
                            ? "text-center text-white"
                            : "text-center text-info"
                        }
                        style={{ fontSize: "2em" }}
                      >
                        <Money amount={parseFloat(amount)} />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {this.state.amount && (
              <FormButton label="Top Up" loading={this.state.loading} />
            )}
          </form>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    countries: countriesOptionsSelector(state),
    products: productsHashSelector(state),
  };
}

InternationalTopupForm.propTypes = {
  t: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(PropTypes.object).isRequired,
  products: PropTypes.shape({}).isRequired,
  submit: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(
  withTranslation("translations")(InternationalTopupForm)
);
