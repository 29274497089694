import {
  LOGOUT,
  FETCH_CURRENT_USER_SUCCESS,
  FETCH_APP_TRANSACTIONS_SUCCESS
} from "../types";

export default function appTransactions(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_CURRENT_USER_SUCCESS:
    case FETCH_APP_TRANSACTIONS_SUCCESS:
      return { ...state, ...action.payload.entities.appTransactions };
    case LOGOUT:
      return {};
    default:
      return state;
  }
}
