import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import PageTitle from "../layout/PageTitle";
import CardsList from "../cards/CardsList";
import { withTranslation } from "react-i18next";

const CardsPage = (props) => (
  <div>
    <PageTitle title={props.t("headers.yourCards")} />

    <div className="row">
      <div className="col col-lg-8 offset-lg-2 col-10 offset-1">
        <div className="card">
          <div className="card-body">
            <h4>
              <Link to="/dashboard">
                <i className="far fa-arrow-alt-circle-left" />
              </Link>{" "}
              {props.t("headers.cardsList")}
            </h4>
            <CardsList />
          </div>
        </div>
      </div>
    </div>
  </div>
);

CardsPage.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation("translations")(CardsPage);
