import { LOGOUT, FETCH_CURRENT_USER_SUCCESS } from "../types";

export default function transactions(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_CURRENT_USER_SUCCESS:
      return { ...state, ...action.payload.entities.transactions };
    case LOGOUT:
      return {};
    default:
      return state;
  }
}
