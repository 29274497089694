import {
  INITIAL_APP_LOAD_SUCCESS,
  FETCH_CURRENT_USER_SUCCESS,
  LOGOUT,
  SET_LANGUAGE
} from "../types";

export default function app(
  state = { loaded: false, user: false, lang: "en" },
  action = {}
) {
  switch (action.type) {
    case FETCH_CURRENT_USER_SUCCESS:
      return { ...state, user: true };
    case LOGOUT:
      return { ...state, user: false };
    case INITIAL_APP_LOAD_SUCCESS:
      return { ...state, loaded: true };
    case SET_LANGUAGE:
      return { ...state, lang: action.payload };
    default:
      return state;
  }
}
