import jwt from "jsonwebtoken";
import { LS_NAME } from "./constants";

export const persistToken = (token) => {
  localStorage.setItem(LS_NAME, token);
};

export const removeToken = () => {
  localStorage.removeItem(LS_NAME);
};

export const getToken = () => {
  const token = localStorage.getItem(LS_NAME);

  if (token && !isExpired(token)) {
    return token;
  }

  return null;
};

const isExpired = (token) => {
  const decoded = jwt.decode(token);
  return decoded.exp * 1000 < Date.now();
};
