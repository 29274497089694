import { schema } from "normalizr";

export const cardSchema = new schema.Entity(
  "cards",
  {},
  { idAttribute: "_id" }
);

export const transactionSchema = new schema.Entity(
  "transactions",
  {},
  { idAttribute: "TigoOrderID" }
);

export const appTransactionSchema = new schema.Entity(
  "appTransactions",
  {},
  { idAttribute: "_id" }
);

export const userSchema = new schema.Entity(
  "users",
  {
    cards: [cardSchema],
    transactions: [transactionSchema],
    appTransactions: [appTransactionSchema]
  },
  { idAttribute: "_id" }
);

export const countrySchema = new schema.Entity(
  "countries",
  {},
  { idAttribute: "CountryID" }
);

export const carrierSchema = new schema.Entity(
  "carriers",
  {},
  { idAttribute: "CarrierID" }
);

export const productSchema = new schema.Entity(
  "products",
  {},
  { idAttribute: "ProductID" }
);
