import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Carousel } from "react-responsive-carousel";
import { connect } from "react-redux";

class Promotions extends React.Component {
  state = {
    showModal: false,
    chosenPromotion: this.props.promotions[0]
  };

  toggleModal = promotion =>
    this.setState({
      showModal: !this.state.showModal,
      chosenPromotion: promotion ? promotion : this.state.chosenPromotion
    });

  render() {
    return (
      <div
        style={{
          marginBottom: "30px"
        }}
      >
        {this.props.promotions.length > 0 && (
          <Carousel
            showStatus={false}
            showThumbs={false}
            autoPlay
            interval={10000}
            onClickItem={index =>
              this.toggleModal(this.props.promotions[index])
            }
          >
            {this.props.promotions.map((item, index) => (
              <div key={index}>
                {item.narrowImage && (
                  <img
                    onClick={() => this.toggleModal(item)}
                    alt="Promotion Banner"
                    className="img-fluid"
                    src={item.narrowImage}
                    style={{
                      cursor: "pointer"
                    }}
                  />
                )}
              </div>
            ))}
          </Carousel>
        )}

        <Modal isOpen={this.state.showModal} size="lg">
          <ModalHeader toggle={this.toggleModal}>Promotion Details</ModalHeader>
          <ModalBody>
            <p>{this.state.chosenPromotion.fullDescription}</p>
            {this.state.chosenPromotion.imageUrl && (
              <p>
                <img
                  src={this.state.chosenPromotion.imageUrl}
                  alt="Full Scale Promotion Banner"
                  className="img-fluid"
                />
              </p>
            )}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    promotions: state.promos.filter(item => item.narrowImage)
  };
}

Promotions.propTypes = {
  promotions: PropTypes.arrayOf(
    PropTypes.shape({
      narrowImage: PropTypes.string,
      shortDescription: PropTypes.string,
      imageUrl: PropTypes.string,
      fullDescription: PropTypes.string
    })
  ).isRequired
};

export default connect(mapStateToProps)(Promotions);
