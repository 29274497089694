import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import isPostalCode from "validator/lib/isPostalCode";
import isCreditCard from "validator/lib/isCreditCard";
import MaskedInput from "react-text-mask";
import FormButton from "../elements/FormButton";
import history from "../../history";
import { withTranslation } from "react-i18next";

const initialFormData = {
  firstName: "",
  lastName: "",
  address: "",
  city: "",
  state: "",
  zip: "",
  cardNumber: "",
  cvv: "",
  expiration: "",
  save: false,
};

class CardForm extends React.Component {
  state = {
    data: initialFormData,
    errors: {},
    loading: false,
  };

  onChangeString = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };

  toggleSave = () => {
    this.setState({
      data: { ...this.state.data, save: !this.state.data.save },
    });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    if (!this.validate()) return;

    this.setState({ loading: true });
    try {
      const res = await this.props.onSubmit(this.state.data);
      if (res.action === "redirect") {
        this.setState({ loading: false });
        history.push(res.action.data);
      }
      if (res.action === "clear") {
        this.clearForm();
      }
    } catch (err) {
      this.setState({ errors: err.errors, loading: false });
    }
  };

  validate = () => {
    const errors = {};
    const data = this.state.data;

    if (!data.firstName) errors.firstName = "errors.empty";
    if (!data.lastName) errors.lastName = "errors.empty";
    if (!data.address) errors.address = "errors.empty";
    if (!data.city) errors.city = "errors.empty";
    if (data.state.length === 0) errors.state = "errors.empty";
    if (!isPostalCode(data.zip, "US")) errors.zip = "errors.invalidZip";
    if (!isCreditCard(data.cardNumber))
      errors.cardNumber = "errors.invalidCardNumber";
    const dateData = data.expiration.split("/");
    const now = new Date();
    const month = parseInt(dateData[0], 10);
    const year = parseInt(dateData[1], 10);
    const currentYear = parseInt(now.getFullYear().toString().substr(-2), 10);
    if (
      year < currentYear ||
      (year === currentYear && month < now.getMonth() + 1)
    )
      errors.expiration = "errors.cardExpiration";
    if (!data.cvv) errors.cvv = "errors.empty";
    if (data.cvv.length > 4 || data.cvv.length < 3) errors.cvv = "errors.cvv";

    this.setState({ errors });
    return Object.keys(errors).length === 0;
  };

  clearForm = () => {
    this.setState({ data: initialFormData, loading: false });
  };

  render() {
    const { data, errors, loading } = this.state;
    const t = this.props.t;

    return (
      <div>
        {this.state.errors.global ? (
          <div className="alert alert-danger">
            {t(this.state.errors.global)}{" "}
            <a onClick={() => this.setState({ errors: {} })}>GOT IT</a>
          </div>
        ) : (
          <form onSubmit={this.onSubmit}>
            <h4>
              {!this.props.hideBackLink && (
                <Link to="/cards">
                  <i className="far fa-arrow-alt-circle-left" />
                </Link>
              )}{" "}
              {t("headers.billingDetails")}
            </h4>
            <hr />

            <div className="row">
              <div className="col-md-6 col-12">
                <div className="form-group">
                  <label htmlFor="firstName">{t("cardForm.firstName")}</label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    className={
                      errors.firstName
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    onChange={this.onChangeString}
                    value={data.firstName}
                    disabled={loading}
                  />
                  <div className="invalid-feedback form-text">
                    {t(errors.firstName)}
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="form-group">
                  <label htmlFor="lastName">{t("cardForm.lastName")}</label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    className={
                      errors.lastName
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    onChange={this.onChangeString}
                    value={data.lastName}
                    disabled={loading}
                  />
                  <div className="invalid-feedback form-text">
                    {t(errors.lastName)}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="address">{t("cardForm.address")}</label>
                  <input
                    type="text"
                    id="address"
                    name="address"
                    className={
                      errors.address
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    onChange={this.onChangeString}
                    value={data.address}
                    disabled={loading}
                  />
                  <div className="invalid-feedback form-text">
                    {t(errors.address)}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 col-12">
                <div className="form-group">
                  <label htmlFor="city">{t("cardForm.city")}</label>
                  <input
                    type="text"
                    id="city"
                    name="city"
                    className={
                      errors.city ? "form-control is-invalid" : "form-control"
                    }
                    onChange={this.onChangeString}
                    value={data.city}
                    disabled={loading}
                  />
                  <div className="invalid-feedback form-text">
                    {t(errors.city)}
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="form-group">
                  <label htmlFor="state">{t("cardForm.state")}</label>
                  <select
                    className={
                      errors.city ? "form-control is-invalid" : "form-control"
                    }
                    value={data.state}
                    onChange={this.onChangeString}
                    name="state"
                    disabled={loading}
                  >
                    <option value="">State</option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District Of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </select>
                  <div className="invalid-feedback form-text">
                    {t(errors.state)}
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="form-group">
                  <label htmlFor="zip">{t("cardForm.zip")}</label>
                  <input
                    type="text"
                    id="zip"
                    name="zip"
                    className={
                      errors.zip ? "form-control is-invalid" : "form-control"
                    }
                    onChange={this.onChangeString}
                    value={data.zip}
                    disabled={loading}
                  />
                  <div className="invalid-feedback form-text">
                    {t(errors.zip)}
                  </div>
                </div>
              </div>
            </div>

            <h4>{t("headers.cardDetails")}</h4>
            <hr />

            <div className="row">
              <div className="col-md-4 col-12">
                <div className="form-group">
                  <label htmlFor="cardNumber">{t("cardForm.cardNumber")}</label>
                  <MaskedInput
                    type="text"
                    id="cardNumber"
                    name="cardNumber"
                    className={
                      errors.cardNumber
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    onChange={this.onChangeString}
                    value={data.cardNumber}
                    disabled={loading}
                    mask={[
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      " ",
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      " ",
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      " ",
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                    ]}
                    placeholder="4111 1111 1111 1111"
                  />
                  <div className="invalid-feedback form-text">
                    {t(errors.cardNumber)}
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="form-group">
                  <label htmlFor="expiration">{t("cardForm.expiration")}</label>
                  <MaskedInput
                    type="text"
                    id="expiration"
                    name="expiration"
                    className={
                      errors.expiration
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    onChange={this.onChangeString}
                    value={data.expiration}
                    disabled={loading}
                    mask={[/\d/, /\d/, "/", /\d/, /\d/]}
                    placeholder="01/22"
                  />
                  <div className="invalid-feedback form-text">
                    {t(errors.expiration)}
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="form-group">
                  <label htmlFor="cvv">{t("cardForm.cvv")}</label>
                  <input
                    type="password"
                    id="cvv"
                    name="cvv"
                    className={
                      errors.cvv ? "form-control is-invalid" : "form-control"
                    }
                    onChange={this.onChangeString}
                    value={data.cvv}
                    disabled={loading}
                  />
                  <div className="invalid-feedback form-text">
                    {t(errors.cvv)}
                  </div>
                </div>
              </div>
            </div>

            {this.props.charge && (
              <div className="row">
                <div className="col-12">
                  <div className="form-group form-check">
                    <input
                      type="checkbox"
                      id="save"
                      name="save"
                      className={
                        errors.save
                          ? "form-check-input is-invalid"
                          : "form-check-input"
                      }
                      onChange={this.toggleSave}
                      value={data.save}
                      disabled={loading}
                    />
                    <label htmlFor="save">{t("cardForm.save")}</label>
                  </div>
                </div>
              </div>
            )}

            <FormButton
              label={
                this.props.charge ? t("buttons.charge") : t("buttons.addCard")
              }
              loading={loading}
            />
          </form>
        )}
      </div>
    );
  }
}

CardForm.propTypes = {
  t: PropTypes.func.isRequired,
  charge: PropTypes.bool,
  hideBackLink: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

CardForm.defaultProps = {
  charge: false,
  hideBackLink: false,
};

export default connect(null)(withTranslation("translations")(CardForm));
