import axios from "axios";
import { getToken, removeToken } from "./persist";
import { BASE_API_URL } from "./constants";

// const axiosInstance = axios.create({
//   baseURL: BASE_API_URL,
// });

const parseErrors = (err) => {
  console.log(err.response);
  if (err.response) {
    switch (err.response.status) {
      case 401:
        removeToken();
        window.location = "/login";
        break;
      default:
        throw err.response.data;
    }
  } else if (err.request) {
    console.log(err, "network error");
  } else {
    console.log("something unique happened");
  }
};

const createHeaders = (additionalHeaders = {}) => {
  const headers = {
    "Content-Type": "application/json",
    ...additionalHeaders,
  };

  const token = getToken();
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return headers;
};

export const makeRequest = async (
  method,
  url,
  data = {},
  additionalHeaders = {}
) => {
  const headers = createHeaders(additionalHeaders);

  try {
    const res = await axios({
      method,
      url,
      data,
      headers,
    });
    return res.data;
  } catch (err) {
    parseErrors(err);
  }
};
