import React from "react";
import PropTypes from "prop-types";
import glamorous from "glamorous";
import colors from "../../colors";

const FormButton = ({ label, color, ...props }) => {
  const Button = glamorous.button({
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    backgroundColor: colors[color],
    color: "#fff",
    ":hover": {
      backgroundColor: colors.darkTeal
    }
  });

  return (
    <Button {...props} className="btn" type="submit">
      {label}
    </Button>
  );
};

FormButton.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export default FormButton;
