import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Link } from "react-router-dom";
import _ from "lodash";
import Money from "../elements/Money";
import FormButton from "../elements/FormButton";
import { cardsCollectionSelector } from "../../selectors";
import { fetchAppTransactionsRequest } from "../../actions/appTransactions";
import ChargeCardWrapper from "../cards/ChargeCardWrapper";
import { withTranslation } from "react-i18next";
import { makeRequest } from "../../utils/request";

class ConfirmTransaction extends Component {
  state = {
    amount: this.props.transaction.Amount,
    card:
      this.props.cards.length > 0
        ? _.find(this.props.cards, { isDefault: true })._id
        : null,
    cvv: "",
    errors: {},
    showModal: false,
    loading: false,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.cards.length > 0 && this.props.cards.length === 0) {
      this.setState({
        card: _.find(nextProps.cards, { isDefault: true })._id,
      });
    }
  }

  onChangeCard = (e) =>
    this.setState({
      cvv: "",
      card: e.target.value,
    });

  onChangeCvv = (e) => {
    if (e.target.value.length > 4) return;
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  hasCvv = () => {
    if (this.props.cards.length > 0) {
      return _.find(this.props.cards, { _id: this.state.card }).hasCvv;
    }
    return false;
  };

  onSubmit = (e) => {
    e.preventDefault();
    if (!this.hasCards()) return;

    if (this.validate()) {
      const url = `/api/topups/international`;
      this.setState({ loading: true });
      makeRequest("post", url, {
        data: {
          productId: this.props.transaction.CCID,
          amount: this.total(),
          fee: this.props.transaction.Fee || 0,
          cvv: Number(this.state.cvv),
          card: this.state.card,
          phone: this.props.transaction.InternationalNumber,
          accountId: this.props.transaction.AccountID,
        },
      })
        .then(() => {
          toastr.success(
            this.props.t("toastr.internationTopupSuccess.title"),
            this.props.t("toastr.internationTopupSuccess.content")
          );
          this.onSuccessfulTransaction();
        })
        .catch((err) => {
          if (err.errors.global) {
            toastr.error("Server Error", this.props.t(err.errors.global));
          }
          this.setState({ loading: false, errors: err.errors });
        });
    }
  };

  onSuccessfulTransaction = () => {
    this.props.onSuccess();
    this.props.fetchAppTransactionsRequest();
    this.setState({ loading: false });
  };

  validate = () => {
    const errors = {};

    if (parseFloat(this.state.amount) <= 0) errors.amount = "errors.zeroAmount";
    if (this.hasCards() && !this.hasCvv() && !this.state.cvv)
      errors.cvv = "errors.empty";

    this.setState({ errors });
    return Object.values(errors).length === 0;
  };

  total = () =>
    this.props.transaction.Amount + (this.props.transaction.Fee || 0);

  hasCards = () => this.props.cards.length > 0;

  toggleModal = () => this.setState({ showModal: !this.state.showModal });

  render() {
    return (
      <div>
        <form onSubmit={this.onSubmit}>
          <Modal isOpen={this.state.showModal} toggle={this.toggleModal}>
            <ModalHeader toggle={this.toggleModal}>
              {this.props.t("headers.noCreditCard")}
            </ModalHeader>
            <ModalBody>
              <p>{this.props.t("messages.noCards")}</p>
              <p>
                <Link to="/cards/new">{this.props.t("links.addCard")}</Link>
              </p>
            </ModalBody>
          </Modal>

          <p className="alert alert-danger">
            {this.props.t("text.internationalConfirmMessage")}
          </p>
          {this.state.errors.amount && (
            <div className="alert alert-danger">
              {this.props.t(this.state.errors.amount)}
            </div>
          )}
          <div className="row mt-5">
            <div className="col-md-3 m-md-auto">
              <h3 className="card-title text-center">
                {this.props.t("headers.amount")}
              </h3>
              {this.props.transaction.Fee > 0 && (
                <h6 className="text-center">
                  <Money amount={this.props.transaction.Amount} /> +{" "}
                  <Money amount={this.props.transaction.Fee} /> (Fee)
                </h6>
              )}
              <div
                className="text-center text-info"
                style={{ fontSize: "2em" }}
              >
                <Money amount={this.total()} />
              </div>

              {this.hasCards() && (
                <div className="form-group mt-3">
                  <label htmlFor="card">{this.props.t("topupForm.card")}</label>
                  <select
                    id="card"
                    name="card"
                    className={
                      this.state.errors.card
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    onChange={this.onChangeCard}
                    value={this.state.card}
                    disabled={this.state.loading}
                  >
                    {this.props.cards.map((card) => (
                      <option key={card._id} value={card._id}>
                        **** {card.lastFour}
                      </option>
                    ))}
                  </select>
                  <div className="invalid-feedback form-text">
                    {this.props.t(this.state.errors.card)}
                  </div>
                </div>
              )}

              {this.hasCards() && !this.hasCvv() && (
                <div className="form-group">
                  <label htmlFor="cvv">{this.props.t("cardForm.cvv")}</label>
                  <input
                    type="password"
                    id="cvv"
                    name="cvv"
                    className={
                      this.state.errors.cvv
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    onChange={this.onChangeCvv}
                    value={this.state.cvv}
                    disabled={this.state.loading}
                  />
                  <div className="invalid-feedback form-text">
                    {this.props.t(this.state.errors.cvv)}
                  </div>
                </div>
              )}
            </div>

            <div className="col-md-6 mt-md-0 mt-5">
              <h4>{this.props.t("headers.transactionDetails")}</h4>
              <hr />
              <p>
                {this.props.t("labels.internationalNumber")}:{" "}
                {this.props.transaction.InternationalNumber}
              </p>
              <p>
                {this.props.t("labels.country")}:{" "}
                {this.props.transaction.Country}
              </p>
              <p>
                {this.props.t("labels.carrier")}:{" "}
                {this.props.transaction.Carrier}
              </p>
            </div>
          </div>
          {this.hasCards() && (
            <div className="row">
              <div className="col-12">
                <FormButton label="Top Up" loading={this.state.loading} />
              </div>
            </div>
          )}
        </form>
        {!this.hasCards() && (
          <div className="pt-3">
            <hr />
            <ChargeCardWrapper
              additionalData={{
                amount: this.total(),
                phone: this.props.transaction.InternationalNumber,
                accountId: this.props.transaction.AccountID,
                productId: this.props.transaction.CCID,
              }}
              topup="international"
              success={this.onSuccessfulTransaction}
            />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    cards: cardsCollectionSelector(state),
  };
}

ConfirmTransaction.propTypes = {
  amountEditable: PropTypes.bool,
  transaction: PropTypes.shape({
    CCID: PropTypes.number.isRequired,
    Amount: PropTypes.number.isRequired,
    InternationalNumber: PropTypes.string.isRequired,
    Country: PropTypes.string.isRequired,
    Carrier: PropTypes.string.isRequired,
    Fee: PropTypes.number.isRequired,
    AccountID: PropTypes.string,
  }).isRequired,
  t: PropTypes.func.isRequired,
  cards: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSuccess: PropTypes.func.isRequired,
  fetchAppTransactionsRequest: PropTypes.func.isRequired,
};

ConfirmTransaction.defaultProps = {
  amountEditable: false,
};

export default connect(mapStateToProps, { fetchAppTransactionsRequest })(
  withTranslation("translations")(ConfirmTransaction)
);
