import { combineReducers } from "redux";
import { reducer as toastrReducer } from "react-redux-toastr";

import app from "./reducers/app";
import forms from "./reducers/forms";
import cards from "./reducers/cards";
import user from "./reducers/user";
import transactions from "./reducers/transactions";
import appTransactions from "./reducers/appTransactions";
import countries from "./reducers/countries";
import carriers from "./reducers/carriers";
import products from "./reducers/products";
import ui from "./reducers/ui";
import admin from "./reducers/admin";
import promos from "./reducers/promos";
import callPackages from "./reducers/callPackages";

export default combineReducers({
  app,
  cards,
  forms,
  user,
  transactions,
  countries,
  carriers,
  products,
  ui,
  admin,
  promos,
  appTransactions,
  callPackages,
  toastr: toastrReducer,
});
