import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import Table from "react-table";
import PageTitle from "../layout/PageTitle";
import Money from "../elements/Money";
import { withTranslation } from "react-i18next";
import { makeRequest } from "../../utils/request";

const tableColumns = [
  {
    Header: "Date",
    accessor: "date",
    Cell: (row) => <span>{moment(row.value).format("MM/DD/YY HH:mm:ss")}</span>,
  },
  {
    Header: "Amount",
    accessor: "amount",
    Cell: (row) => <Money amount={Math.abs(row.value)} />,
  },
  {
    Header: "Type",
    accessor: "type",
  },
  {
    Header: "Details",
    accessor: "details",
    Cell: (row) => (
      <div>
        <small>{row.value.country}</small>
        <br />
        <small>{row.value.carrier}</small>
        <br />
        <small>{row.value.internationalNumber}</small>
      </div>
    ),
  },
];

class TransactionsPage extends React.Component {
  state = {
    transactions: [],
    loading: true,
  };

  async componentDidMount() {
    const from = moment().subtract(30, "days").format("MM/DD/YY");
    const to = moment().format("MM/DD/YY");

    const data = await makeRequest(
      "get",
      `/api/history?phone=${this.props.phone}&from=${from}&to=${to}`
    );
    this.setState({
      transactions: data.history.map((item) => ({
        ...item,
        details: {
          carrier: item.carrier,
          country: item.country,
          internationalNumber: item.internationalNumber,
          usaNumber: item.usaNumber,
          type: item.type,
        },
      })),
      loading: false,
    });
  }

  render() {
    return (
      <div>
        <PageTitle title={this.props.t("headers.userTransactions")} />

        <div className="row">
          <div className="col col-lg-8 offset-lg-2 col-10 offset-1">
            <div className="card">
              <div className="card-body">
                <Table
                  data={this.state.transactions}
                  columns={tableColumns}
                  pageSize={this.state.pageSize}
                  onPageSizeChange={(pageSize) => this.setState({ pageSize })}
                  loading={this.state.loading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TransactionsPage.propTypes = {
  phone: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    phone: state.user.phone,
  };
}

export default connect(mapStateToProps)(
  withTranslation("translations")(TransactionsPage)
);
