import React, { useState, useEffect } from "react";
import AdminNavbar from "./AdminNavbar";
import { makeRequest } from "../../utils/request";
import FormButton from "../elements/FormButton";

const initialLists = {
  ips: "",
  cards: "",
  products: "",
  deviceIds: "",
  underAttack: false,
};

const AdminBlockedLists = () => {
  const [lists, setLists] = useState(initialLists);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    makeRequest("get", "/api/admin/blockedlists").then((data) =>
      setLists(data.lists)
    );
  }, []);

  const onTextChange = (e) => {
    setLists({ ...lists, [e.target.name]: e.target.value });
  };

  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await makeRequest("put", "/api/admin/blockedlists", { lists });
    setLoading(false);
  };

  return (
    <div>
      <div className="container-fluid">
        <AdminNavbar />

        <div className="row mt-5">
          <div className="col col-lg-8 offset-lg-2 col-10 offset-1">
            <div className="card">
              <div className="card-body">
                <h2 className="card-title">Block Lists</h2>

                <form onSubmit={submit}>
                  {/* TODO: This IP blocking needs work. Its buggy */}
                  {/* <div className="form-group">
                    <label htmlFor="ips">Blocked IPs</label>
                    <textarea
                      id="ips"
                      name="ips"
                      className="form-control"
                      onChange={onTextChange}
                      value={lists.ips}
                      placeholder="Comma-separated list of IPs"
                      rows={5}
                    />
                  </div> */}

                  <div className="form-group">
                    <label htmlFor="cards">Blocked Cards</label>
                    <textarea
                      id="cards"
                      name="cards"
                      className="form-control"
                      onChange={onTextChange}
                      value={lists.cards}
                      placeholder="Comma-separated list of last 4 digits"
                      rows={5}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="products">Blocked Products</label>
                    <textarea
                      id="products"
                      name="products"
                      className="form-control"
                      onChange={onTextChange}
                      value={lists.products}
                      placeholder="Comma-separated list of Product IDs"
                      rows={5}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="deviceIds">Blocked Device IDs</label>
                    <textarea
                      id="deviceIds"
                      name="deviceIds"
                      className="form-control"
                      onChange={onTextChange}
                      value={lists.deviceIds}
                      placeholder="Comma-separated list of Device IDs"
                      rows={5}
                    />
                  </div>

                  <div className="form-group form-check">
                    <label className="form-check-label" htmlFor="underAttack">
                      <input
                        type="checkbox"
                        id="underAttack"
                        name="underAttack"
                        className="form-check-input"
                        onChange={() =>
                          setLists({
                            ...lists,
                            underAttack: !lists.underAttack,
                          })
                        }
                        value={lists.underAttack}
                        checked={lists.underAttack}
                      />
                      Under Attack!
                    </label>
                  </div>

                  <FormButton label="Save" loading={loading} />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminBlockedLists;
