import React, { Component } from "react";
import PropTypes from "prop-types";
import Fade from "react-reveal";
import ConfirmTransaction from "./ConfirmTransaction";
import InternationalTopupForm from "../forms/InternationalTopupForm";
import TransactionRow from "./TransactionRow";
import { withTranslation } from "react-i18next";

class InternationalTransactionsHistory extends Component {
  state = {
    section: this.props.section,
    transaction: null,
    amountEditable: false,
  };

  newTransaction = (transaction) =>
    this.setState({
      section: "confirm",
      transaction,
      amountEditable: false,
    });

  render() {
    return (
      <div>
        <h3 className="card-title">
          {(this.state.section === "form" ||
            this.state.section === "confirm") && (
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ section: "history" });
              }}
            >
              <i className="far fa-arrow-alt-circle-left" />
            </a>
          )}{" "}
          {this.props.t("headers.internationalTopup")}
        </h3>
        <hr />
        {this.state.section === "form" && (
          <Fade>
            <InternationalTopupForm submit={this.newTransaction} />
          </Fade>
        )}

        {this.state.section === "confirm" && (
          <Fade>
            <ConfirmTransaction
              transaction={this.state.transaction}
              transactionType="international"
              onSuccess={() =>
                this.setState({ section: "history", transaction: null })
              }
            />
          </Fade>
        )}

        {this.state.section === "history" && (
          <Fade>
            <p>
              {this.props.t("text.repeatPreviousTransaction")}{" "}
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ section: "form" });
                }}
              >
                {this.props.t("links.makeTopup")}
              </a>
            </p>
            <table className="table">
              <thead>
                <tr>
                  <th>Phone</th>
                  <th>Amount</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {this.props.transactions.map((transaction) => (
                  <TransactionRow
                    transaction={{
                      ...transaction,
                      Fee: this.props.products[transaction.CCID]
                        ? this.props.products[transaction.CCID].Fee
                        : 0,
                    }}
                    key={transaction.TigoOrderID}
                    repeat={(item) =>
                      this.setState({ transaction: item, section: "confirm" })
                    }
                  />
                ))}
              </tbody>
            </table>
          </Fade>
        )}
      </div>
    );
  }
}

InternationalTransactionsHistory.propTypes = {
  transactions: PropTypes.arrayOf(PropTypes.object).isRequired,
  t: PropTypes.func.isRequired,
  section: PropTypes.string.isRequired,
  products: PropTypes.shape({}).isRequired,
};

export default withTranslation("translations")(
  InternationalTransactionsHistory
);
