import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Spinner from "react-spinkit";
import history from "../../history";
import { cardsCollectionSelector } from "../../selectors";
import { withTranslation } from "react-i18next";
import { deleteCardThunk, setCardAsDefaultThunk } from "../../actions/thunks";

class CardsList extends React.Component {
  componentDidMount() {
    if (this.props.cards.length === 0) history.push("/dashboard");
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.cards.length === 0) history.push("/dashboard");
  }

  render() {
    return (
      <div>
        <table className="table">
          <thead>
            <tr>
              <th>Card</th>
              <th>Default?</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {this.props.cards.map((card) => (
              <tr key={card._id}>
                <td style={{ fontFamily: "monospace" }}>
                  ****&nbsp;{card.lastFour}
                </td>
                <td>
                  {card.isDefault ? (
                    <span className="badge badge-primary">
                      {this.props.t("labels.default")}
                    </span>
                  ) : (
                    <button
                      onClick={() => this.props.setCardAsDefaultThunk(card._id)}
                      className="btn btn-info btn-sm"
                    >
                      {this.props.t("labels.makeDefault")}
                    </button>
                  )}
                </td>
                <td>
                  <button
                    onClick={() => this.props.deleteCardThunk(card._id)}
                    className="btn btn-danger btn-sm"
                    disabled={this.props.deleteCardInProgress}
                  >
                    {this.props.deleteCardInProgress ? (
                      <span>
                        <Spinner name="circle" fadeIn="none" color="white" />
                      </span>
                    ) : (
                      <span aria-hidden="true">&times;</span>
                    )}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <hr />

        <Link to="/cards/new" className="btn btn-primary">
          Add new card
        </Link>
      </div>
    );
  }
}

CardsList.propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      isDefault: PropTypes.bool.isRequired,
      lastFour: PropTypes.string.isRequired,
      _id: PropTypes.string.isRequired,
    })
  ).isRequired,
  t: PropTypes.func.isRequired,
  makeDefaultRequest: PropTypes.func.isRequired,
  deleteCardRequest: PropTypes.func.isRequired,
  deleteCardInProgress: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    cards: cardsCollectionSelector(state),
    deleteCardInProgress: state.ui.deleteCardInProgress,
  };
}

export default connect(mapStateToProps, {
  setCardAsDefaultThunk,
  deleteCardThunk,
})(withTranslation("translations")(CardsList));
