import React from "react";
import _ from "lodash";
import { normalize } from "normalizr";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Alert } from "reactstrap";
import FormButton from "../elements/FormButton";
import { userSchema } from "../../schemas";
import { adminUserPushError, updateUser } from "../../actions/admin";
import { makeRequest } from "../../utils/request";

class PushNotificationForm extends React.Component {
  state = {
    data: {
      title: "",
      body: "",
      tokens: [],
    },
    showSidebar: false,
    users: [],
    query: "",
    filter: "all",
    lang: "all",
    errors: {},
    loading: false,
    message: "",
  };

  componentDidMount() {
    this.setState({
      data: {
        ...this.state.data,
        tokens: this.props.users.map((item) => item.pushToken),
      },
      users: this.props.users,
    });
  }

  onChangeString = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });

  onSubmit = async (e, type) => {
    e.preventDefault();
    if (!type) return;
    if (!this.validate()) return;
    try {
      this.setState({ loading: true });
      let endpoint = "/api/notifications/send";
      switch (type) {
        case "all":
          endpoint = "/api/notifications/send/all";
          break;
        case "unknown":
          endpoint = "/api/notifications/send/unknown";
          break;
        case "en":
          endpoint = "/api/notifications/send/english";
        case "es":
          endpoint = "/api/notifications/send/spanish";
          break;
        case "sp":
          endpoint = "/api/notifications/send/spanish";
          break;
        case "fr":
          endpoint = "/api/notifications/send/french";
          break;
        default:
          endpoint = "/api/notifications/send";
          break;
      }
      const res = await makeRequest("post", endpoint, {
        notification: this.state.data,
      });
      this.setState({
        loading: false,
        message: `${res.count} notifications sent, ${res.errored} errors`,
      });
      const payload = normalize(res.users, [userSchema]);
      this.props.adminUserPushError(payload);

      this.setState({
        data: {
          title: "",
          body: "",
          tokens: this.props.users.map((item) => item.pushToken),
        },
        filter: "all",
        query: "",
        showSidebar: false,
        lang: "all",
      });
    } catch (err) {
      this.setState({ errors: err.errors, loading: false });
    }
  };

  validate = () => {
    const errors = {};

    if (!this.state.data.title) errors.title = "Can't be blank";
    if (!this.state.data.body) errors.body = "Can't be blank";
    if (this.state.data.tokens.length === 0)
      errors.global = "No users selected";

    this.setState({ errors });
    return Object.keys(errors).length === 0;
  };

  handleCheckbox = (e) => {
    if (e.target.checked) {
      this.setState({
        data: {
          ...this.state.data,
          tokens: [...this.state.data.tokens, e.target.value],
        },
      });
    } else {
      this.setState({
        data: {
          ...this.state.data,
          tokens: this.state.data.tokens.filter(
            (item) => item !== e.target.value
          ),
        },
      });
    }
  };

  toggleVisible = () => {
    const visibleTokens = this.filteredUsers().map((item) => item.pushToken);
    if (this.hasCheckedUsers()) {
      this.setState({
        data: {
          ...this.state.data,
          tokens: this.state.data.tokens.filter(
            (item) => !visibleTokens.includes(item)
          ),
        },
      });
    } else {
      this.setState({
        data: {
          ...this.state.data,
          tokens: [...this.state.data.tokens, ...visibleTokens],
        },
      });
    }
  };

  hasCheckedUsers = () => {
    const visibleTokens = this.filteredUsers().map((item) => item.pushToken);
    return _.intersection(this.state.data.tokens, visibleTokens).length > 0;
  };

  handleQuery = (e) => {
    this.setState({ query: e.target.value });
  };

  handleFilterChange = (filter) => {
    this.setState({ filter, query: "" });
  };

  handleLanguageChange = (lang) => {
    this.setState({ lang, query: "" });
  };

  filteredUsers = () => {
    let users = [...this.props.users];
    if (this.state.filter === "selected") {
      users = users.filter((user) =>
        this.state.data.tokens.includes(user.pushToken)
      );
    }
    if (this.state.filter === "empty") {
      users = users.filter(
        (user) => !this.state.data.tokens.includes(user.pushToken)
      );
    }
    if (this.state.query) {
      users = _.filter(users, (item) => {
        return (
          item.firstName.toLowerCase().includes(this.state.query) ||
          item.lastName.toLowerCase().includes(this.state.query) ||
          item.email.toLowerCase().includes(this.state.query) ||
          item.phone.includes(this.state.query)
        );
      });
    }
    if (this.state.lang !== "all") {
      users = _.filter(users, (item) => item.lang === this.state.lang);
    }

    return users;
  };

  removeFromPushList = async (user) => {
    const res = await makeRequest("put", `/api/admin/users/${user._id}`, {
      pushToken: "",
      pushNotificationError: false,
    });
    this.props.updateUser(res.user);
    this.setState({
      data: {
        ...this.state.data,
        tokens: this.state.data.tokens.filter(
          (item) => item !== user.pushToken
        ),
      },
    });
  };

  render() {
    const { errors, data, loading } = this.state;
    return (
      <div className="row mt-3">
        <div className={this.state.showSidebar ? "col-md-7" : "col-12"}>
          <div className="card">
            <div className="card-body">
              <p className="mb-3">
                Push notification will be sent to:{" "}
                <a
                  href="#"
                  onClick={() => this.setState({ showSidebar: true })}
                >
                  {this.state.data.tokens.length} users
                </a>
                .
              </p>
              {this.state.message && (
                <Alert
                  color="success"
                  isOpen={this.state.message}
                  toggle={() => this.setState({ message: "" })}
                >
                  {this.state.message}
                </Alert>
              )}
              {this.state.errors.global && (
                <p className="text-danger">{this.state.errors.global}</p>
              )}

              <form>
                <div className="form-group">
                  <label htmlFor="title">Title</label>
                  <input
                    type="text"
                    id="title"
                    name="title"
                    className={
                      errors.title ? "form-control is-invalid" : "form-control"
                    }
                    onChange={this.onChangeString}
                    value={data.title}
                    disabled={loading}
                  />
                  <div className="invalid-feedback form-text">
                    {errors.title}
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="body">Body</label>
                  <textarea
                    id="body"
                    name="body"
                    className={
                      errors.body ? "form-control is-invalid" : "form-control"
                    }
                    onChange={this.onChangeString}
                    value={data.body}
                    disabled={loading}
                  />
                  <div className="invalid-feedback form-text">
                    {errors.body}
                  </div>
                </div>
                <div className="mt-3">
                  <FormButton
                    label="Send"
                    loading={loading}
                    onClick={(e) => this.onSubmit(e, "send")}
                  />
                </div>
                <div className="mt-3">
                  <FormButton
                    label="Send All"
                    loading={loading}
                    onClick={(e) => this.onSubmit(e, "all")}
                  />
                </div>
                <div className="mt-3">
                  <FormButton
                    label="Send Unknown Lang"
                    loading={loading}
                    onClick={(e) => this.onSubmit(e, "unknown")}
                  />
                </div>
                <div className="mt-3">
                  <FormButton
                    label="Send Spanish Users"
                    loading={loading}
                    onClick={(e) => this.onSubmit(e, "es")}
                  />
                </div>
                <div className="mt-3">
                  <FormButton
                    label="Send English Users"
                    loading={loading}
                    onClick={(e) => this.onSubmit(e, "en")}
                  />
                </div>
                <div className="mt-3">
                  <FormButton
                    label="Send French Users"
                    loading={loading}
                    onClick={(e) => this.onSubmit(e, "fr")}
                  />
                </div>
              </form>

              <small className="text-muted mt-5">
                You can use placeholders both in Title and in Body: [firstname],
                [lastname], [phone], [email]
              </small>
            </div>
          </div>

          {this.props.usersWithError.length > 0 && (
            <div className="card mt-4">
              <div className="card-body">
                <p>
                  Error occurred when deliver push notifications for following
                  users:
                </p>

                <table className="table">
                  <thead>
                    <tr>
                      <th>User</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.usersWithError.map((user) => (
                      <tr key="user._id">
                        <td>
                          {user.firstName} {user.lastName} ({user.email} -{" "}
                          {user.phone})
                        </td>
                        <td>
                          <button
                            onClick={() => this.removeFromPushList(user)}
                            className="btn btn-danger"
                          >
                            Remove From Push List
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>

        {this.state.showSidebar && (
          <div className="col-md-5">
            <div className="card">
              <div className="card-body">
                <h4>Choose users to send notification to.</h4>

                <div className="form-group">
                  <input
                    type="search"
                    className="form-control"
                    value={this.state.query}
                    onChange={this.handleQuery}
                    placeholder="Search by name, email or phone"
                  />
                </div>

                <div className="form-group">
                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      id="lang-all"
                      className="form-check-input"
                      value="all"
                      checked={this.state.lang === "all"}
                      onChange={() => this.setState({ lang: "all" })}
                    />
                    <label className="form-check-label" htmlFor="lang-all">
                      All
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      id="lang-unknown"
                      className="form-check-input"
                      value="-"
                      checked={this.state.lang === "-"}
                      onChange={() => this.setState({ lang: "-" })}
                    />
                    <label className="form-check-label" htmlFor="lang-unknown">
                      Unknown
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      id="lang-en"
                      className="form-check-input"
                      value="en"
                      checked={this.state.lang === "en"}
                      onChange={() => this.setState({ lang: "en" })}
                    />
                    <label className="form-check-label" htmlFor="lang-en">
                      English
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      id="lang-sp"
                      className="form-check-input"
                      value="sp"
                      checked={this.state.lang === "sp"}
                      onChange={() => this.setState({ lang: "sp" })}
                    />
                    <label className="form-check-label" htmlFor="lang-sp">
                      Spanish (sp)
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      id="lang-es"
                      className="form-check-input"
                      value="es"
                      checked={this.state.lang === "es"}
                      onChange={() => this.setState({ lang: "es" })}
                    />
                    <label className="form-check-label" htmlFor="lang-es">
                      Spanish (es)
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      id="lang-fr"
                      className="form-check-input"
                      value="fr"
                      checked={this.state.lang === "fr"}
                      onChange={() => this.setState({ lang: "fr" })}
                    />
                    <label className="form-check-label" htmlFor="lang-fr">
                      French
                    </label>
                  </div>
                </div>

                <div>
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <a
                        href="#"
                        onClick={() => this.handleFilterChange("all")}
                        className={`nav-link ${
                          this.state.filter === "all" && "active"
                        }`}
                      >
                        All
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#"
                        onClick={() => this.handleFilterChange("selected")}
                        className={`nav-link ${
                          this.state.filter === "selected" && "active"
                        }`}
                      >
                        Selected
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#"
                        onClick={() => this.handleFilterChange("empty")}
                        className={`nav-link ${
                          this.state.filter === "empty" && "active"
                        }`}
                      >
                        Not-Selected
                      </a>
                    </li>
                  </ul>
                </div>

                <table className="table mt-3">
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          checked={this.hasCheckedUsers()}
                          onChange={this.toggleVisible}
                        />
                      </th>
                      <th>User</th>
                      <th>Lang</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.filteredUsers().map((user) => (
                      <tr key={user._id}>
                        <td>
                          <input
                            type="checkbox"
                            checked={this.state.data.tokens.includes(
                              user.pushToken
                            )}
                            value={user.pushToken}
                            onChange={this.handleCheckbox}
                          />
                        </td>
                        <td>
                          {user.firstName} {user.lastName}
                          <br />
                          <small>
                            {user.email} - {user.phone}
                          </small>
                        </td>
                        <td>{user.lang || "-"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

PushNotificationForm.propTypes = {
  users: PropTypes.array.isRequired,
  usersWithError: PropTypes.array.isRequired,
  adminUserPushError: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
};

export default connect(null, { adminUserPushError, updateUser })(
  PushNotificationForm
);
