import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { normalize } from "normalizr";
import { toastr } from "react-redux-toastr";
import CardForm from "../forms/CardForm";
import { cardSchema } from "../../schemas";
import { updateUser } from "../../actions/users";
import { addCardSuccess } from "../../actions/cards";
import { withTranslation } from "react-i18next";
import { makeRequest } from "../../utils/request";

const ChargeCardWrapper = ({
  addCardSuccess,
  t,
  additionalData,
  topup,
  updateUser,
  success,
}) => {
  async function chargeCard(card) {
    let url, toastrMessage;
    let data = { card };

    switch (topup) {
      case "sinpin":
        url = "/api/topups/sinpin";
        toastrMessage = "sinpinTopupSuccess";
        data.card = { ...data.card, amount: additionalData.amount };
        break;
      case "international":
        url = "/api/topups/international";
        toastrMessage = "internationalTopupSuccess";
        data.card = {
          ...data.card,
          amount: additionalData.amount,
          phone: additionalData.phone,
          accountId: additionalData.accountId,
          productId: additionalData.productId,
        };
        break;
      case "package":
        url = `/api/topups/callpackage/${additionalData.callpackageId}`;
        toastrMessage = "callPackageSuccess";
        break;
    }

    try {
      const res = await makeRequest("post", url, { data });
      if (topup === "sinpin") updateUser({ balance: res.balance });
      if (card.save) {
        addCardSuccess(normalize(res.card, cardSchema));
        toastr.success(
          t("toastr.cardAdded.title"),
          t("toastr.cardAdded.content")
        );
        toastr.success(
          t(`toastr.${toastrMessage}.title`),
          t(`toastr.${toastrMessage}.content`)
        );
        success();
        return { action: "none" };
      } else {
        toastr.success(
          t(`toastr.${toastrMessage}.title`),
          t(`toastr.${toastrMessage}.content`)
        );
        success();
        return {
          action: "clear",
        };
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }

  return (
    <div className="pt-3">
      <CardForm onSubmit={chargeCard} charge hideBackLink />
    </div>
  );
};

ChargeCardWrapper.propTypes = {
  addCardSuccess: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  additionalData: PropTypes.object.isRequired,
  topup: PropTypes.string.isRequired,
  success: PropTypes.func,
};

ChargeCardWrapper.defaultProps = {
  success: () => ({}),
};

export default connect(null, { addCardSuccess, updateUser })(
  withTranslation("translations")(ChargeCardWrapper)
);
