import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Money from "./Money";

const BalanceBlock = props => (
  <div>
    <div className="d-none d-md-block">
      <div
        className="card h-100"
        style={{ maxHeight: "400px", minHeight: "250px" }}
      >
        <div className="card-body d-flex align-items-center justify-content-center">
          <div>
            <h3 className="card-title text-center">{props.title}</h3>
            <div className="text-center text-info" style={{ fontSize: "3em" }}>
              <Money amount={props.balance} />
            </div>
          </div>
        </div>
        {props.hasCards && (
          <div className="card-footer text-center">
            <Link to="/cards" className="btn btn-secondary">
              {props.cardsLink}
            </Link>
          </div>
        )}
      </div>
    </div>
    <div className="d-block d-md-none">
      <div className="card">
        <div className="card-body d-flex align-items-center justify-content-center">
          <div>
            <h5 className="card-title text-center">{props.title}</h5>
            <div
              className="text-center text-info"
              style={{ fontSize: "1.5em" }}
            >
              <Money amount={props.balance} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

BalanceBlock.propTypes = {
  title: PropTypes.string.isRequired,
  cardsLink: PropTypes.string.isRequired,
  balance: PropTypes.number.isRequired,
  hasCards: PropTypes.bool.isRequired
};

export default BalanceBlock;
