import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import isMobilePhone from "validator/lib/isMobilePhone";
import isEmail from "validator/lib/isEmail";
import FormButton from "../elements/FormButton";
import { loginSuccess } from "../../actions/auth";
import { withTranslation } from "react-i18next";
import { makeRequest } from "../../utils/request";
import { loggedInThunk } from "../../actions/thunks";

class SignupForm extends Component {
  state = {
    data: {
      phone: "",
      smsCode: "",
      firstName: "",
      lastName: "",
      password: "",
      passwordConfirmation: "",
      email: "",
    },
    errors: {},
    loading: false,
    smsSent: false,
    smsVerified: false,
  };

  onChangeText = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });

  submitPhone = (e) => {
    e.preventDefault();
    const errors = {};
    if (!isMobilePhone(this.state.data.phone, "en-US"))
      errors.phone = "errors.phone";
    if (this.state.data.phone.length !== 10) errors.phone = "errors.phone";
    this.setState({ errors });
    if (Object.values(errors).length === 0) {
      makeRequest("post", "/api/users/send_sms", {
        phone: this.state.data.phone,
      })
        .then(() => this.setState({ smsSent: true }))
        .catch((err) => this.setState({ errors: err.errors }));
    }
  };

  submitCode = (e) => {
    e.preventDefault();
    const errors = {};
    if (this.state.data.smsCode.length === 0) errors.smsCode = "errors.smsCode";
    this.setState({ errors });
    if (Object.values(errors).length === 0) {
      makeRequest("post", "/api/users/verify_code", {
        code: this.state.data.smsCode,
        phone: this.state.data.phone,
      })
        .then(() => this.setState({ smsVerified: true }))
        .catch((err) => this.setState({ errors: err.errors }));
    }
  };

  submitUser = (e) => {
    e.preventDefault();

    const errors = {};
    if (this.state.data.firstName.length === 0)
      errors.firstName = "errors.empty";
    if (this.state.data.lastName.length === 0) errors.lastName = "errors.empty";
    if (this.state.data.password.length === 0) errors.password = "errors.empty";
    if (this.state.data.password !== this.state.data.passwordConfirmation)
      errors.password = "errors.match";
    this.setState({ errors });

    if (Object.values(errors).length === 0) {
      this.setState({ loading: true });
      let dataToSend = this.state.data;
      if (!isEmail(this.state.data.email)) {
        dataToSend = {
          ...dataToSend,
          email: `${this.state.data.phone}@${this.state.data.phone}.com`,
        };
      }
      makeRequest("post", "/api/users", { user: dataToSend })
        .then((res) => {
          this.props.loggedInThunk(res.token);
        })
        .catch((err) => this.setState({ errors: err.errors, loading: false }));
    }
  };

  render() {
    return (
      <div>
        <form onSubmit={this.submitPhone}>
          <div className="form-group">
            <label htmlFor="phone" className="control-label">
              {this.props.t("signupForm.phoneLabel")}
            </label>
            <div className="input-group">
              <input
                type="tel"
                id="phone"
                name="phone"
                maxLength={10}
                className="form-control"
                value={this.state.data.phone}
                onChange={this.onChangeText}
                disabled={this.state.smsSent}
              />
              {!this.state.smsSent && (
                <div className="input-group-append">
                  <button className="btn btn-info" type="submit">
                    {this.props.t("buttons.send")}
                  </button>
                </div>
              )}
            </div>
            {this.state.errors.phone && (
              <span>{this.props.t(this.state.errors.phone)}</span>
            )}
          </div>
        </form>

        {this.state.smsSent && (
          <form onSubmit={this.submitCode}>
            <div className="form-group">
              <label htmlFor="smsCode" className="control-label">
                {this.props.t("signupForm.codeLabel")}
              </label>
              <div className="input-group">
                <input
                  type="text"
                  id="smsCode"
                  name="smsCode"
                  className="form-control"
                  value={this.state.data.smsCode}
                  onChange={this.onChangeText}
                  placeholder={this.props.t("signupForm.codePlaceholder")}
                  disabled={this.state.smsVerified}
                />
                {!this.state.smsVerified && (
                  <div className="input-group-append">
                    <button className="btn btn-info" type="submit">
                      {this.props.t("buttons.send")}
                    </button>
                  </div>
                )}
              </div>
              {this.state.errors.smsCode && (
                <span>{this.props.t(this.state.errors.smsCode)}</span>
              )}
            </div>
          </form>
        )}

        {this.state.smsVerified && (
          <form onSubmit={this.submitUser}>
            <div className="form-group">
              <label htmlFor="firstName" className="control-label">
                {this.props.t("signupForm.firstNameLabel")}
              </label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                className="form-control"
                disabled={this.state.loading}
                value={this.state.data.firstName}
                onChange={this.onChangeText}
              />
              {this.state.errors.firstName && (
                <span>{this.props.t(this.state.errors.firstName)}</span>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="lastName" className="control-label">
                {this.props.t("signupForm.lastNameLabel")}
              </label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                className="form-control"
                disabled={this.state.loading}
                value={this.state.data.lastName}
                onChange={this.onChangeText}
              />
              {this.state.errors.lastName && (
                <span>{this.props.t(this.state.errors.lastName)}</span>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="email" className="control-label">
                {`${this.props.t("signupForm.emailLabel")} (Optional)`}
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="form-control"
                disabled={this.state.loading}
                value={this.state.data.email}
                onChange={this.onChangeText}
              />
              {this.state.errors.email && (
                <span>{this.props.t(this.state.errors.email)}</span>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="password" className="control-label">
                {this.props.t("signupForm.passwordLabel")}
              </label>
              <input
                type="password"
                id="password"
                name="password"
                className="form-control"
                disabled={this.state.loading}
                value={this.state.data.password}
                onChange={this.onChangeText}
              />
              {this.state.errors.password && (
                <span>{this.props.t(this.state.errors.password)}</span>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="passwordConfirmation" className="control-label">
                {this.props.t("signupForm.passwordConfirmationLabel")}
              </label>
              <input
                type="password"
                id="passwordConfirmation"
                name="passwordConfirmation"
                className="form-control"
                disabled={this.state.loading}
                value={this.state.data.passwordConfirmation}
                onChange={this.onChangeText}
              />
              {this.state.errors.passwordConfirmation && (
                <span>
                  {this.props.t(this.state.errors.passwordConfirmation)}
                </span>
              )}
            </div>

            <FormButton
              label={this.props.t("signupForm.createAccountButton")}
              loading={this.state.loading}
            />
          </form>
        )}
      </div>
    );
  }
}

SignupForm.propTypes = {
  t: PropTypes.func.isRequired,
  loginSuccess: PropTypes.func.isRequired,
};

export default connect(null, { loggedInThunk })(
  withTranslation("translations")(SignupForm)
);
