import { createSelector } from "reselect";
import orderBy from "lodash/orderBy";
import filter from "lodash/filter";
import startsWith from "lodash/startsWith";
import toLower from "lodash/toLower";

export const cardsHashSelector = (state) => state.cards;
export const transactionsHashSelector = (state) => state.transactions;
export const countriesHashSelector = (state) => state.countries;
export const productsHashSelector = (state) => state.products;
export const appTransactionsHashSelector = (state) => state.appTransactions;

export const cardsCollectionSelector = createSelector(
  cardsHashSelector,
  (cardsHash) => Object.values(cardsHash)
);

export const productsCollectionSelector = createSelector(
  productsHashSelector,
  (productsHash) => Object.values(productsHash)
);

export const usaProductsSelector = createSelector(
  productsCollectionSelector,
  (products) =>
    orderBy(
      filter(filter(products, { Country: "1+USA" }), (i) => {
        if (!startsWith(toLower(i.Carrier), "vidgo")) {
          return i;
        }
      }),
      ["Carrier"]
    )
);

export const sortedCardsSelector = createSelector(
  cardsCollectionSelector,
  (cards) => orderBy(cards, ["isDefault"], ["desc"])
);

export const internationalTransactionsSelector = createSelector(
  transactionsHashSelector,
  (hash) => filter(hash, (transaction) => transaction.Country !== "1+USA")
);

export const internationalTransactionsWithAmountsSelector = createSelector(
  internationalTransactionsSelector,
  productsHashSelector,
  (transactions, productsHash) =>
    transactions.map((item) => ({
      ...item,
      Amounts: productsHash[item.CCID]
        ? productsHash[item.CCID].Amounts.split(",")
        : [],
    }))
);

export const countriesOptionsSelector = createSelector(
  countriesHashSelector,
  (countriesHash) =>
    orderBy(
      filter(
        countriesHash,
        (country) => country.Active && country.Description !== "1+USA"
      ),
      ["Description"]
    )
);

export const appTransactionsSelector = createSelector(
  appTransactionsHashSelector,
  (hash) => orderBy(Object.values(hash), ["createdAt"], ["desc"])
);

export const latestAppTransactionsSelector = createSelector(
  appTransactionsSelector,
  (transactions) => transactions.slice(0, 5)
);
