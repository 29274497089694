import { FETCH_CARRIERS_SUCCESS } from "../types";

export default function carriers(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_CARRIERS_SUCCESS:
      return { ...state, ...action.payload.entities.carriers };
    default:
      return state;
  }
}
