import React from "react";
import Spinner from "react-spinkit";

const AppLoading = () => (
  <div
    className="text-center d-flex justify-content-center align-items-center"
    style={{
      minHeight: "100vh"
    }}
  >
    <Spinner fadeIn="none" name="line-scale" />
  </div>
);

export default AppLoading;
