import {
  LOGOUT,
  LOAD_ADMIN_SUCCESS,
  UPDATE_SETTINGS_SUCCESS,
  ADMIN_UPDATED_USER,
  ADMIN_USER_PUSH_ERROR,
} from "../../types";

export default function users(state = {}, action = {}) {
  switch (action.type) {
    case UPDATE_SETTINGS_SUCCESS:
      return {
        ...state,
        [action.payload._id]: {
          ...action.payload,
          transactionsLimit: action.payload.transactionsLimit,
          amountLimit: action.payload.amountLimit,
        },
      };
    case ADMIN_UPDATED_USER:
      return {
        ...state,
        [action.payload._id]: action.payload,
      };
    case ADMIN_USER_PUSH_ERROR:
      return {
        ...state,
        ...action.payload.entities.users,
      };
    case LOAD_ADMIN_SUCCESS:
      return action.payload.entities.users || {};
    case LOGOUT:
      return {};
    default:
      return state;
  }
}
