import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { normalize } from "normalizr";
import { toastr } from "react-redux-toastr";
import CardForm from "../forms/CardForm";
import { cardSchema } from "../../schemas";
import { addCardSuccess } from "../../actions/cards";
import { withTranslation } from "react-i18next";
import { makeRequest } from "../../utils/request";

const AddCardWrapper = ({ addCardSuccess, t }) => {
  async function addCard(card) {
    try {
      const res = await makeRequest("post", "/api/cards", { card });
      addCardSuccess(normalize(res.card, cardSchema));
      toastr.success(
        t("toastr.cardAdded.title"),
        t("toastr.cardAdded.content")
      );
      return {
        action: "redirect",
        data: "/dashboard",
      };
    } catch (err) {
      return Promise.reject(err);
    }
  }

  return (
    <div>
      <CardForm onSubmit={addCard} />
    </div>
  );
};

AddCardWrapper.propTypes = {
  addCardSuccess: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default connect(null, { addCardSuccess })(
  withTranslation("translations")(AddCardWrapper)
);
