import React from "react";
import PropTypes from "prop-types";
import Spinner from "react-spinkit";
import glamorous from "glamorous";
import colors from "../../colors";

const Button = glamorous.button({
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  backgroundColor: colors.teal,
  color: "#fff",
  ":hover": {
    backgroundColor: colors.darkTeal
  }
});

const FormButton = ({ label, loading }) => (
  <div style={{ textAlign: "center" }}>
    <Button className="btn btn-block" type="submit" disabled={loading}>
      {loading ? <Spinner name="circle" color="white" fadeIn="none" /> : label}
    </Button>
  </div>
);

FormButton.propTypes = {
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired
};

export default FormButton;
