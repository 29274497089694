import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Link } from "react-router-dom";
import _ from "lodash";
import { toastr } from "react-redux-toastr";
import FormButton from "../elements/FormButton";
import { cardsCollectionSelector } from "../../selectors";
import api from "../../api";
import { updateUser } from "../../actions/users";
import { fetchAppTransactionsRequest } from "../../actions/appTransactions";
import ChargeCardWrapper from "../cards/ChargeCardWrapper";
import { withTranslation } from "react-i18next";

class SinpinTopupForm extends Component {
  state = {
    data: {
      amount: "",
      card:
        this.props.cards.length > 0
          ? _.find(this.props.cards, { isDefault: true })._id
          : null,
      cvv: "",
    },
    errors: {},
    showModal: false,
    loading: false,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.cards.length > 0 && this.props.cards.length === 0) {
      this.setState({
        data: {
          ...this.state.data,
          card: _.find(nextProps.cards, { isDefault: true })._id,
        },
      });
    }
  }

  onChangeNumber = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  onChangeCvv = (e) => {
    if (e.target.value.length > 4) return;
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  onChangeCard = (e) =>
    this.setState({
      data: {
        ...this.state.data,
        cvv: "",
        card: e.target.value,
      },
    });

  hasCvv = () => {
    if (this.props.cards.length > 0) {
      return _.find(this.props.cards, { _id: this.state.data.card }).hasCvv;
    }
    return false;
  };

  onSubmit = (e) => {
    e.preventDefault();
    if (!this.hasCards()) return;

    const errors = this.validate(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });
      api.topups
        .sinpinRecharge(this.state.data)
        .then(({ balance }) => {
          this.setState({
            loading: false,
            data: { ...this.state.data, amount: "", cvv: "" },
          });
          this.props.updateUser({ balance });
          this.props.fetchAppTransactionsRequest();
          toastr.success(
            this.props.t("toastr.sinpinTopupSuccess.title"),
            this.props.t("toastr.sinpinTopupSuccess.content")
          );
        })
        .catch((err) => this.setState({ errors: err.errors, loading: false }));
    }
  };

  validate = (data) => {
    const errors = {};
    if (!data.amount) errors.amount = "errors.empty";
    if (this.hasCards() && !this.hasCvv() && !data.cvv)
      errors.cvv = "errors.empty";
    if (data.amount <= 0 || data.amount > 99)
      errors.amount = "errors.amountInRange";
    return errors;
  };

  hasCards = () => this.props.cards.length > 0;

  toggleModal = () => this.setState({ showModal: !this.state.showModal });

  render() {
    const { loading, errors, data } = this.state;
    const { t } = this.props;

    return (
      <div>
        <Modal isOpen={this.state.showModal} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal}>No Credit Card</ModalHeader>
          <ModalBody>
            <p>
              You need to connect credit card to your account to be able to
              topup.
            </p>
            <p>
              <Link to="/cards/new">Add Credit Card</Link>
            </p>
          </ModalBody>
        </Modal>

        {this.state.errors.global ? (
          <div className="alert alert-danger">
            {this.state.errors.global.startsWith("errors.")
              ? t(this.state.errors.global)
              : this.state.errors.global}{" "}
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ errors: {} });
              }}
            >
              GOT IT
            </a>
          </div>
        ) : (
          <div>
            <form onSubmit={this.onSubmit}>
              <h3 className="card-title">Top Up Sinpin Account</h3>
              <hr />
              <div className="form-group">
                <label htmlFor="amount">{t("topupForm.amount")}</label>
                <div className="input-group input-group-lg">
                  <div className="input-group-prepend">
                    <span className="input-group-text">$</span>
                  </div>
                  <input
                    type="number"
                    id="amount"
                    step="any"
                    min="1"
                    max="100"
                    name="amount"
                    className={
                      errors.amount ? "form-control is-invalid" : "form-control"
                    }
                    onChange={this.onChangeNumber}
                    value={data.amount}
                    disabled={loading}
                  />
                  <div className="invalid-feedback form-text">
                    {t(errors.amount)}
                  </div>
                </div>
              </div>

              {this.hasCards() && (
                <div className="row">
                  <div className="col">
                    {" "}
                    <div className="form-group">
                      <label htmlFor="card">{t("topupForm.card")}</label>
                      <select
                        id="card"
                        name="card"
                        className={
                          errors.card
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        onChange={this.onChangeCard}
                        value={data.card}
                        disabled={loading}
                      >
                        {this.props.cards.map((card) => (
                          <option key={card._id} value={card._id}>
                            **** {card.lastFour}
                          </option>
                        ))}
                      </select>
                      <div className="invalid-feedback form-text">
                        {t(errors.card)}
                      </div>
                    </div>
                  </div>
                  {!this.hasCvv() && (
                    <div className="col">
                      {" "}
                      <div className="form-group">
                        <label htmlFor="cvv">{t("cardForm.cvv")}</label>
                        <input
                          type="password"
                          id="cvv"
                          name="cvv"
                          className={
                            errors.cvv
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          onChange={this.onChangeCvv}
                          value={data.cvv}
                          disabled={loading}
                        />
                        <div className="invalid-feedback form-text">
                          {t(errors.cvv)}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {this.hasCards() && (
                <FormButton
                  id="topup-button"
                  label={t("buttons.charge")}
                  loading={loading}
                />
              )}
            </form>

            {!this.hasCards() && this.state.data.amount > 0 && (
              <ChargeCardWrapper
                additionalData={{ amount: this.state.data.amount }}
                topup="sinpin"
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    cards: cardsCollectionSelector(state),
  };
}

SinpinTopupForm.propTypes = {
  t: PropTypes.func.isRequired,
  cards: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateUser: PropTypes.func.isRequired,
  fetchAppTransactionsRequest: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  updateUser,
  fetchAppTransactionsRequest,
})(withTranslation("translations")(SinpinTopupForm));
