import React, { Component } from "react";
import PageTitle from "../layout/PageTitle";
import NewPasswordForm from "../forms/NewPasswordForm.js";
import { withTranslation } from "react-i18next";
import { makeRequest } from "../../utils/request";

class ResetPasswordPage extends Component {
  state = {
    loading: true,
    valid: false,
  };

  async componentDidMount() {
    const token = this.props.match.params.token;
    try {
      await makeRequest("post", `/api/auth/verify_reset_token`, { token });
      this.setState({ loading: false, valid: true });
    } catch (err) {
      this.setState({ loading: false, valid: false });
    }
  }

  render() {
    const t = this.props.t;

    return (
      <div>
        <PageTitle title={t("headers.resetPassword")} />

        <div className="row">
          <div className="col col-lg-8 offset-lg-2 col-10 offset-1">
            <div className="card">
              <div className="card-body">
                {this.state.loading && (
                  <div className="alert alert-info">
                    {t("messages.verifyingToken")}
                  </div>
                )}

                {!this.state.loading && !this.state.valid && (
                  <div className="alert alert-danger">
                    {t("messages.invalidToken")}
                  </div>
                )}

                {!this.state.loading && this.state.valid && (
                  <NewPasswordForm token={this.props.match.params.token} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation("translations")(ResetPasswordPage);
