import React from "react";
import { makeRequest } from "../../utils/request";
import size from "lodash/size";
import toString from "lodash/toString";
import AdminNavbar from "./AdminNavbar";
import FormButton from "../elements/FormButton";

class AdminPhoneIntelligencePage extends React.Component {
  state = {
    phone: "",
    country: "US",
    results: {},
    errors: {},
    loading: false,
  };

  onSubmit = async (e) => {
    const { phone, country } = this.state;
    e.preventDefault();
    if (this.validate()) {
      try {
        this.setState({ loading: true, errors: {} });
        const data = {
          phone,
          country,
        };
        const res = await makeRequest(
          "get",
          `/api/admin/users/phone_intelligence/${phone}/${country}`
        );
        this.setState({ results: res.phoneData, loading: false });
      } catch (error) {
        this.setState({
          loading: false,
          errors: { phone: "Error Submitting Form" },
        });
      }
    }
  };

  validate = () => {
    const errors = {};

    if (!this.state.phone) errors.phone = "Invalid Phone Number";
    if (size(this.state.phone) < 7) errors.phone = "Invalid Phone Number";
    if (!this.state.country) errors.country = "Invalid Country";
    this.setState({ errors });
    return Object.values(errors).length === 0;
  };

  render() {
    const { results } = this.state;
    return (
      <div className="container-fluid">
        <AdminNavbar />
        <div className="row mt-5">
          <div className="col col-lg-8 offset-lg-2 col-10 offset-1">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title">Search Phone Intelligence</h3>
                <hr />
                <form onSubmit={this.onSubmit}>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="phone">Phone</label>
                          <input
                            type="tel"
                            id="phone"
                            name="phone"
                            className={
                              this.state.errors.phone
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            onChange={(e) =>
                              this.setState({ phone: e.target.value })
                            }
                            value={this.state.phone}
                            disabled={this.state.loading}
                          />
                          <div className="invalid-feedback form-text">
                            {this.state.errors.phone}
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="country">ISO Country</label>
                          <input
                            type="text"
                            id="country"
                            name="country"
                            className={
                              this.state.errors.country
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            onChange={(e) =>
                              this.setState({ country: e.target.value })
                            }
                            value={this.state.country}
                            disabled={this.state.loading}
                          />
                          <div className="invalid-feedback form-text">
                            {this.state.errors.country}
                          </div>
                        </div>
                      </div>
                    </div>
                    <FormButton
                      id="submit"
                      label="Submit"
                      loading={this.state.loading}
                    />
                  </div>
                </form>
                {size(this.state.results) > 0 ? (
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Data</th>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>id</td>
                        <td>{toString(results.id)}</td>
                      </tr>
                      <tr>
                        <td>phone_number</td>
                        <td>{toString(results.phone_number)}</td>
                      </tr>
                      <tr>
                        <td>is_valid</td>
                        <td>{toString(results.is_valid)}</td>
                      </tr>
                      <tr>
                        <td>country_calling_code</td>
                        <td>{toString(results.country_calling_code)}</td>
                      </tr>
                      <tr>
                        <td>country_code</td>
                        <td>{toString(results.country_code)}</td>
                      </tr>
                      <tr>
                        <td>country_name</td>
                        <td>{toString(results.country_name)}</td>
                      </tr>
                      <tr>
                        <td>line_type</td>
                        <td>{toString(results.line_type)}</td>
                      </tr>
                      <tr>
                        <td>carrier</td>
                        <td>{toString(results.carrier)}</td>
                      </tr>
                      <tr>
                        <td>is_prepaid</td>
                        <td>{toString(results.is_prepaid)}</td>
                      </tr>
                      <tr>
                        <td>first_seen_days</td>
                        <td>{toString(results.first_seen_days)}</td>
                      </tr>
                      <tr>
                        <td>last_seen_days</td>
                        <td>{toString(results.last_seen_days)}</td>
                      </tr>
                      <tr>
                        <td>popularity</td>
                        <td>{toString(results.popularity)}</td>
                      </tr>
                      <tr>
                        <td>velocity</td>
                        <td>{toString(results.velocity)}</td>
                      </tr>
                      <tr>
                        <td>volatility</td>
                        <td>{toString(results.volatility)}</td>
                      </tr>
                    </tbody>
                  </table>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AdminPhoneIntelligencePage;
