import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import Table from "react-table";
import _ from "lodash";
import { connect } from "react-redux";
import { updateUser } from "../../actions/admin";
import SettingsForm from "../forms/SettingsForm";
import Money from "../elements/Money";
import AdminNavbar from "./AdminNavbar";
import { fetchAdminUsersThunk } from "../../actions/thunks";
import { makeRequest } from "../../utils/request";

const tableColumns = [
  {
    Header: <i className="fas fa-lock" />,
    accessor: "blocked",
    Cell: (row) => (
      <span className="text-danger">
        {row.value ? <i className="fas fa-lock" /> : ""}
      </span>
    ),
    filterable: false,
    width: 50,
    minWidth: 50,
  },
  {
    Header: "First Name",
    accessor: "firstName",
  },
  {
    Header: "Last Name",
    accessor: "lastName",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Phone",
    accessor: "phone",
  },
  {
    Header: "Created At",
    accessor: "createdAt",
    Cell: (row) => <span>{moment(row.value).format("MM/DD/YY")}</span>,
    filterable: false,
  },
  {
    Header: "Status",
    accessor: "status",
  },
];

const transactionColumns = [
  {
    Header: "Date",
    accessor: "date",
    Cell: (row) => <span>{moment(row.value).format("MM/DD/YY HH:mm:ss")}</span>,
  },
  {
    Header: "Amount",
    accessor: "amount",
    Cell: (row) => <Money amount={Math.abs(row.value)} />,
  },
  {
    Header: "Type",
    accessor: "type",
  },
];

function filter(filter, row) {
  const id = filter.pivotId || filter.id;
  return row[id] !== undefined
    ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase())
    : true;
}

class AdminPage extends React.Component {
  state = {
    user: {},
    transaction: {},
    transactions: [],
    transactionsLoading: false,
    usersPageSize: 25,
    transactionsPageSize: 5,
    from: moment().subtract(28, "days"),
    to: moment(),
    focusedInput: null,
  };

  componentDidMount() {
    this.props.fetchAdminUsersThunk();
  }

  selectUser = async (rowInfo) => {
    const user = rowInfo.original;
    this.setState({ user, transactionsLoading: true });
    const res = await makeRequest(
      "get",
      `/api/history?phone=${user.phone}&from=${this.state.from.format(
        "MM/DD/YY"
      )}&to=${this.state.to.format("MM/DD/YY")}`
    );
    this.setState({
      transactions: res.history,
      transactionsLoading: false,
    });
  };

  onDatesChange = async ({ startDate, endDate }) => {
    const prevFrom = this.state.from;
    const prevTo = this.state.to;

    if (
      startDate &&
      endDate &&
      (prevFrom.format("MM/DD/YY") !== startDate.format("MM/DD/YY") ||
        prevTo.format("MM/DD/YY") !== endDate.format("MM/DD/YY"))
    ) {
      this.setState({
        from: startDate,
        to: endDate,
        transactionsLoading: true,
      });
      const res = await makeRequest(
        "get",
        `/api/history?phone=${this.state.user.phone}&from=${startDate.format(
          "MM/DD/YY"
        )}&to=${endDate.format("MM/DD/YY")}`
      );
      this.setState({
        transactions: res.history,
        transactionsLoading: false,
      });
    }
  };

  toggleBlocked = async (value) => {
    const res = await makeRequest(
      "put",
      `/api/admin/users/${this.state.user._id}`,
      {
        blocked: value,
      }
    );
    this.props.updateUser(res.user);
    this.setState({ user: res.user });
  };

  setStatus = async (status) => {
    const res = await makeRequest(
      "put",
      `/api/admin/users/${this.state.user._id}`,
      {
        status,
      }
    );
    this.props.updateUser(res.user);
    this.setState({ user: res.user });
  };

  render() {
    return (
      <div className="container-fluid">
        <Modal
          isOpen={!!this.state.transaction.id}
          toggle={() => this.setState({ transaction: {} })}
        >
          <ModalHeader toggle={() => this.setState({ transaction: {} })}>
            Transaction Details
          </ModalHeader>
          <ModalBody>
            <table className="table table-striped">
              <tbody>
                <tr>
                  <td>
                    <b>Type</b>
                  </td>
                  <td>{this.state.transaction.type}</td>
                </tr>
                <tr>
                  <td>
                    <b>Description</b>
                  </td>
                  <td>{this.state.transaction.description}</td>
                </tr>
                <tr>
                  <td>
                    <b>id</b>
                  </td>
                  <td>{this.state.transaction.id}</td>
                </tr>
                <tr>
                  <td>
                    <b>Date</b>
                  </td>
                  <td>
                    {moment(this.state.transaction.date).format(
                      "MM/DD/YY hh:mm:ss"
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Amount</b>
                  </td>
                  <td>
                    <Money amount={Math.abs(this.state.transaction.amount)} />
                  </td>
                </tr>
                {this.state.transaction.reason && (
                  <tr>
                    <td>
                      <b>Reason</b>
                    </td>
                    <td>{this.state.transaction.reason}</td>
                  </tr>
                )}
                {this.state.transaction.rechargeType && (
                  <tr>
                    <td>
                      <b>Recharge Type</b>
                    </td>
                    <td>{this.state.transaction.rechargeType}</td>
                  </tr>
                )}
                {this.state.transaction.reference && (
                  <tr>
                    <td>
                      <b>Reference</b>
                    </td>
                    <td>{this.state.transaction.reference}</td>
                  </tr>
                )}
                {this.state.transaction.linkedAuditId && (
                  <tr>
                    <td>
                      <b>Linked Audit Id</b>
                    </td>
                    <td>{this.state.transaction.linkedAuditId}</td>
                  </tr>
                )}
                {this.state.transaction.refundedAuditId && (
                  <tr>
                    <td>
                      <b>Refunded Audit Id</b>
                    </td>
                    <td>{this.state.transaction.refundedAuditId}</td>
                  </tr>
                )}
                {this.state.transaction.subscriberAuditId && (
                  <tr>
                    <td>
                      <b>Subscriber Audit Id</b>
                    </td>
                    <td>{this.state.transaction.subscriberAuditId}</td>
                  </tr>
                )}
                {this.state.transaction.tigoId && (
                  <tr>
                    <td>
                      <b>Tigo Order Id</b>
                    </td>
                    <td>{this.state.transaction.tigoId}</td>
                  </tr>
                )}
                {this.state.transaction.carrier && (
                  <tr>
                    <td>
                      <b>Carrier</b>
                    </td>
                    <td>{this.state.transaction.carrier}</td>
                  </tr>
                )}
                {this.state.transaction.country && (
                  <tr>
                    <td>
                      <b>Country</b>
                    </td>
                    <td>{this.state.transaction.country}</td>
                  </tr>
                )}
                {this.state.transaction.internationalNumber && (
                  <tr>
                    <td>
                      <b>International Number</b>
                    </td>
                    <td>{this.state.transaction.internationalNumber}</td>
                  </tr>
                )}
                {this.state.transaction.usaNumber && (
                  <tr>
                    <td>
                      <b>USA Number</b>
                    </td>
                    <td>{this.state.transaction.usaNumber}</td>
                  </tr>
                )}
                {this.state.transaction.amountToSend && (
                  <tr>
                    <td>
                      <b>Amount to Send</b>
                    </td>
                    <td>{this.state.transaction.amountToSend}</td>
                  </tr>
                )}
                {this.state.transaction.confirmationNumber && (
                  <tr>
                    <td>
                      <b>Confirmation Number</b>
                    </td>
                    <td>{this.state.transaction.confirmationNumber}</td>
                  </tr>
                )}
                {this.state.transaction.comment && (
                  <tr>
                    <td>
                      <b>Comment</b>
                    </td>
                    <td>{this.state.transaction.comment}</td>
                  </tr>
                )}
                {this.state.transaction.account && (
                  <tr>
                    <td>
                      <b>Account</b>
                    </td>
                    <td>{this.state.transaction.account}</td>
                  </tr>
                )}
                {this.state.transaction.additionalInformation && (
                  <tr>
                    <td>
                      <b>Additional Information</b>
                    </td>
                    <td>{this.state.transaction.additionalInformation}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </ModalBody>
        </Modal>

        <AdminNavbar />

        <div className="row mt-4">
          <div className={this.state.user._id ? "col-8" : "col-12"}>
            <div className="card">
              <div className="card-body">
                <Table
                  data={_.reverse(_.sortBy(this.props.users, ["createdAt"]))}
                  columns={tableColumns}
                  defaultFilterMethod={filter}
                  filterable
                  pageSize={this.state.usersPageSize}
                  onPageSizeChange={(pageSize) =>
                    this.setState({ usersPageSize: pageSize })
                  }
                  getTrProps={(state, rowInfo) => {
                    if (rowInfo && rowInfo.row) {
                      return {
                        onClick: () => this.selectUser(rowInfo),
                        style: {
                          background:
                            rowInfo.original._id === this.state.user._id
                              ? "#00afec"
                              : "white",
                          color:
                            rowInfo.original._id === this.state.user._id
                              ? "white"
                              : "black",
                        },
                      };
                    } else {
                      return {};
                    }
                  }}
                />
              </div>
            </div>
          </div>

          {this.state.user._id && (
            <div className="col-4">
              <div className="card">
                <div className="card-header">
                  {this.state.user.firstName} {this.state.user.lastName}
                  <button
                    onClick={() =>
                      this.setState({ user: {}, transactions: [] })
                    }
                    type="button"
                    className="close"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="card-body">
                  <dl>
                    <dt>Full Name</dt>
                    <dd>
                      {this.state.user.firstName} {this.state.user.lastName}
                    </dd>
                    <dt>Email</dt>
                    <dd>{this.state.user.email}</dd>
                    <dt>Phone Number</dt>
                    <dd>{this.state.user.phone}</dd>
                    <dt>Member Since</dt>
                    <dd>{moment(this.state.user.createdAt).format("ll")}</dd>
                    <dt>Number of Saved Cards</dt>
                    <dd>{this.state.user.cards.length}</dd>
                    <dt>Device ID</dt>
                    <dd>{this.state.user.deviceId || "UNKNOWN"}</dd>
                    <dt>Mobile Provider</dt>
                    <dd>{this.state.user.provider || "UNKNOWN"}</dd>
                    <dt>Status</dt>
                    <dd>
                      {this.state.user.status ? this.state.user.status : "NEW"}
                    </dd>
                  </dl>

                  {!this.state.user.blocked && (
                    <div>
                      <button
                        onClick={() => this.toggleBlocked(true)}
                        className="btn btn-block btn-danger"
                      >
                        Block User
                      </button>
                    </div>
                  )}

                  {this.state.user.blocked && (
                    <div>
                      <p className="text-danger">User is blocked!</p>
                      <button
                        onClick={() => this.toggleBlocked(false)}
                        className="btn btn-block btn-info"
                      >
                        Restore User
                      </button>
                    </div>
                  )}

                  <br />

                  {(!this.state.user.status ||
                    this.state.user.status === "NEW") && (
                    <div>
                      <button
                        onClick={() => this.setStatus("VERIFIED")}
                        className="btn btn-block btn-success"
                      >
                        VERIFY
                      </button>
                    </div>
                  )}
                  {this.state.user.status &&
                    this.state.user.status === "VERIFIED" && (
                      <div>
                        <button
                          onClick={() => this.setStatus("NEW")}
                          className="btn btn-block btn-success"
                        >
                          Back to NEW status
                        </button>
                      </div>
                    )}
                </div>
              </div>

              <div className="card mt-3">
                <div className="card-header">Limits</div>
                <div className="card-body">
                  <SettingsForm user={this.state.user} />
                </div>
              </div>

              <div className="card mt-3">
                <div className="card-header">Transactions</div>
                <div className="card-body">
                  <div className="text-center">
                    <DateRangePicker
                      startDate={this.state.from}
                      startDateId="startDate-from"
                      endDate={this.state.to}
                      endDateId="endDate-from"
                      onDatesChange={this.onDatesChange}
                      onFocusChange={(focusedInput) =>
                        this.setState({ focusedInput })
                      }
                      isOutsideRange={(day) => {
                        return (
                          day.isBefore(moment().subtract(30, "days")) ||
                          day.isAfter(moment())
                        );
                      }}
                      focusedInput={this.state.focusedInput}
                    />
                  </div>
                  <br />
                  Total Number of Transactions: {this.state.transactions.length}
                  <br />
                  <br />
                  <Table
                    data={this.state.transactions}
                    columns={transactionColumns}
                    pageSize={this.state.transactionsPageSize}
                    onPageSizeChange={(pageSize) =>
                      this.setState({ transactionsPageSize: pageSize })
                    }
                    loading={this.state.transactionsLoading}
                    getTrProps={(state, rowInfo) => {
                      if (rowInfo && rowInfo.row) {
                        return {
                          onClick: () =>
                            this.setState({
                              transaction: {
                                ...rowInfo.original,
                                index: rowInfo.index,
                              },
                            }),
                          style: {
                            background:
                              rowInfo.index === this.state.transaction.index
                                ? "#00afec"
                                : "white",
                            color:
                              rowInfo.index === this.state.transaction.index
                                ? "white"
                                : "black",
                          },
                        };
                      } else {
                        return {};
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    users: Object.values(state.admin.users).filter((item) => item.confirmed),
    settings: state.admin.settings,
    loaded: !_.isEmpty(state.admin.users),
  };
}

AdminPage.propTypes = {
  fetchAdminUsersThunk: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  loaded: PropTypes.bool.isRequired,
  updateUser: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { fetchAdminUsersThunk, updateUser })(
  AdminPage
);
