import { FETCH_COUNTRIES_SUCCESS, UPDATE_COUNTRY } from "../types";

export default function countries(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_COUNTRIES_SUCCESS:
      return { ...state, ...action.payload.entities.countries };
    case UPDATE_COUNTRY:
      return { ...state, [action.payload.CountryID]: action.payload };
    default:
      return state;
  }
}
