import React from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import PropTypes from "prop-types";
import FormButton from "../elements/FormButton";
import { updateSettingsSuccess } from "../../actions/admin";
import { makeRequest } from "../../utils/request";

class SettingsForm extends React.Component {
  state = {
    transactionsLimit: this.props.user.transactionsLimit,
    amountLimit: this.props.user.amountLimit,
    errors: {},
    loading: false,
  };

  componentWillReceiveProps(props) {
    this.setState({
      transactionsLimit: props.user.transactionsLimit,
      amountLimit: props.user.amountLimit,
    });
  }

  onSubmit = (e) => {
    e.preventDefault();
    if (this.validate()) {
      this.setState({ loading: true });
      makeRequest("put", `/api/admin/users/${this.props.user._id}`, {
        transactionsLimit: this.state.transactionsLimit,
        amountLimit: this.state.amountLimit,
      })
        .then((res) => {
          this.props.updateSettingsSuccess(res.user);
          this.setState({ loading: false });
          this.props.onSuccess();
          toastr.success("Limits changed!");
        })
        .catch(() => {
          this.setState({ loading: false });
          toastr.error("Something went wrong");
        });
    }
  };

  validate = () => {
    const errors = {};

    if (!this.state.transactionsLimit)
      errors.transactionsLimit = "Can't be blank";
    if (!this.state.amountLimit) errors.amountLimit = "Can't be blank";

    this.setState({ errors });
    return Object.values(errors).length === 0;
  };

  render() {
    return (
      <div>
        <form onSubmit={this.onSubmit}>
          <div className="form-group">
            <label htmlFor="transactionsLimit">
              Transactions Limit Per Day
            </label>
            <input
              type="number"
              id="transactionsLimit"
              name="transactionsLimit"
              className={
                this.state.errors.transactionsLimit
                  ? "form-control is-invalid"
                  : "form-control"
              }
              onChange={(e) =>
                this.setState({
                  transactionsLimit: parseInt(e.target.value, 10),
                })
              }
              value={this.state.transactionsLimit}
              disabled={this.state.loading}
            />
            <div className="invalid-feedback form-text">
              {this.state.errors.transactionsLimit}
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="amountLimit">Dollar Limit Per Day</label>
            <input
              type="number"
              id="amountLimit"
              name="amountLimit"
              className={
                this.state.errors.amountLimit
                  ? "form-control is-invalid"
                  : "form-control"
              }
              onChange={(e) =>
                this.setState({
                  amountLimit: parseInt(e.target.value, 10),
                })
              }
              value={this.state.amountLimit}
              disabled={this.state.loading}
            />
            <div className="invalid-feedback form-text">
              {this.state.errors.amountLimit}
            </div>
          </div>
          <FormButton label="Save" loading={this.state.loading} />
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    settings: state.admin.settings,
  };
}

SettingsForm.propTypes = {
  user: PropTypes.shape({
    _id: PropTypes.string,
    transactionsLimit: PropTypes.number,
    amountLimit: PropTypes.number,
  }).isRequired,
  onSuccess: PropTypes.func,
  updateSettingsSuccess: PropTypes.func.isRequired,
};

SettingsForm.defaultProps = {
  onSuccess: () => ({}),
};

export default connect(mapStateToProps, { updateSettingsSuccess })(
  SettingsForm
);
