import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AdminNavbar from "./AdminNavbar";
import PushNotificationForm from "../forms/PushNotificationForm";
import { loadAdminRequest } from "../../actions/admin";

class AdminNotificationsPage extends React.Component {
  state = {};

  componentDidMount() {
    if (!this.loaded) {
      this.props.loadAdminRequest();
    }
  }

  render() {
    return (
      <div className="container-fluid">
        <AdminNavbar />
        {this.props.users.length > 0 && (
          <PushNotificationForm
            users={this.props.users}
            usersWithError={this.props.usersWithError}
          />
        )}
      </div>
    );
  }
}

AdminNotificationsPage.propTypes = {
  users: PropTypes.array.isRequired,
  loadAdminRequest: PropTypes.func.isRequired,
  usersWithError: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    users: Object.values(state.admin.users).filter(
      (item) => item.confirmed && item.pushToken
    ),
    usersWithError: Object.values(state.admin.users).filter(
      (item) => item.confirmed && item.pushToken && item.pushNotificationError
    ),
    loaded: Object.keys(state.admin.users).length > 0,
  };
}

export default connect(mapStateToProps, { loadAdminRequest })(
  AdminNotificationsPage
);
