import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";
import Validator from "validator";
import { usaProductsSelector } from "../../selectors";
import Money from "../elements/Money";
import FormButton from "../elements/FormButton";
import { withTranslation } from "react-i18next";

class UsaTopupForm extends React.Component {
  state = {
    usaNumber: "",
    productId: "",
    amount: "",
    product: "",
    boostPin: "",
    errors: {},
    loading: false,
  };

  onProductChange = (e) => {
    const product = _.find(this.props.products, {
      ProductID: parseInt(e.target.value, 10),
    });
    this.setState({
      productId: product.ProductID,
      amount: "",
      product,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    if (this.validate()) {
      this.props.submit({
        Amount: parseFloat(this.state.amount),
        InternationalNumber: this.isBoost()
          ? `${this.state.usaNumber}P${this.state.boostPin}`
          : this.state.usaNumber,
        Country: "USA",
        Carrier: this.state.product.Carrier,
        CCID: this.state.productId,
        Fee: this.state.product.Fee,
      });
    }
  };

  validate = () => {
    const errors = {};

    if (!Validator.isMobilePhone(this.state.usaNumber, "en-US"))
      errors.usaNumber = "errors.notUSANumber";
    if (!this.state.productId) errors.product = "errors.empty";
    const amount = parseFloat(this.state.amount);
    if (amount <= 0) errors.amount = "errors.zeroAmount";
    if (this.state.product.PricingType === "OpenRange") {
      if (
        amount < this.state.product.OpenRangeLow ||
        amount > this.state.product.OpenRangeHigh
      )
        errors.amount = "errors.amountIsNotInRange";
    }
    if (this.isBoost() && this.state.boostPin === "")
      errors.boostPin = "errors.empty";

    this.setState({ errors });
    return Object.values(errors).length === 0;
  };

  isBoost = () => {
    return this.state.productId === 67;
  };

  render() {
    return (
      <form onSubmit={this.onSubmit}>
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label htmlFor="usaNumber">
                {this.props.t("labels.usaNumber")}
              </label>
              <input
                type="tel"
                id="usaNumber"
                name="usaNumber"
                className={
                  this.state.errors.usaNumber
                    ? "form-control is-invalid"
                    : "form-control"
                }
                onChange={(e) => this.setState({ usaNumber: e.target.value })}
                value={this.state.usaNumber}
                disabled={this.state.loading}
              />
              <div className="invalid-feedback form-text">
                {this.props.t(this.state.errors.usaNumber)}
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label htmlFor="product">{this.props.t("labels.carrier")}</label>
              <select
                id="product"
                value={this.state.productId}
                onChange={this.onProductChange}
                className={
                  this.state.errors.product
                    ? "form-control is-invalid"
                    : "form-control"
                }
              >
                <option value="">{this.props.t("labels.selectCarrier")}</option>
                {this.props.products.map((product) => (
                  <option key={product.ProductID} value={product.ProductID}>
                    {product.Carrier}
                  </option>
                ))}
              </select>
              <div className="invalid-feedback form-text">
                {this.props.t(this.state.errors.product)}
              </div>
            </div>
          </div>
        </div>

        {this.state.product.PricingType === "Fixed" && (
          <div className="row">
            {this.state.product.Amounts.split(",").map((amount) => (
              <div className="col" key={amount}>
                <div
                  className={
                    amount === this.state.amount
                      ? "card bg-success m-2"
                      : "card m-2"
                  }
                  onClick={() => this.setState({ amount })}
                  role="button"
                >
                  <div className="card-body d-flex align-items-center justify-content-center">
                    <div
                      className={
                        amount === this.state.amount
                          ? "text-center text-white"
                          : "text-center text-info"
                      }
                      style={{ fontSize: "2em" }}
                    >
                      <Money amount={parseFloat(amount)} />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        {this.state.product.PricingType === "OpenRange" && (
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label htmlFor="amount">
                  Amount (from{" "}
                  <Money amount={this.state.product.OpenRangeLow} /> to{" "}
                  <Money amount={this.state.product.OpenRangeHigh} />)
                </label>
                <div className="input-group input-group-lg">
                  <div className="input-group-prepend">
                    <span className="input-group-text">$</span>
                  </div>
                  <input
                    id="amount"
                    type="number"
                    step="any"
                    min="1"
                    max="300"
                    className={
                      this.state.errors.amount
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    value={this.state.amount}
                    onChange={(e) => this.setState({ amount: e.target.value })}
                  />
                  <div className="invalid-feedback form-text">
                    {this.props.t(this.state.errors.amount)}
                  </div>
                </div>
              </div>
            </div>

            {this.isBoost() && (
              <div className="col">
                <div className="form-group form-group-lg">
                  <label htmlFor="boostPin">Boost PIN:</label>
                  <input
                    id="boostPin"
                    type="text"
                    className={
                      this.state.errors.boostPin
                        ? "form-control form-control-lg is-invalid"
                        : "form-control form-control-lg"
                    }
                    value={this.state.boostPin}
                    onChange={(e) =>
                      this.setState({ boostPin: e.target.value })
                    }
                  />
                  <div className="invalid-feedback form-text">
                    {this.props.t(this.state.errors.boostPin)}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {this.state.amount && (
          <FormButton label="Top Up" loading={this.state.loading} />
        )}
      </form>
    );
  }
}

UsaTopupForm.propTypes = {
  t: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  submit: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    products: usaProductsSelector(state),
  };
}

export default connect(mapStateToProps)(
  withTranslation("translations")(UsaTopupForm)
);
