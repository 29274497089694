import React, { useState, useEffect } from "react";
import ReduxToastr from "react-redux-toastr";
import Routes from "./Routes";
import AppLoading from "./AppLoading";
import { useDispatch, useSelector } from "react-redux";
import { initialLoadThunk } from "../actions/thunks";

const App = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const loaded = useSelector((state) => state.app.loaded);

  useEffect(() => {
    dispatch(initialLoadThunk());
  }, []);

  return (
    <div style={{ fontFamily: "Poppins" }}>
      {loaded ? <Routes user={user || {}} /> : <AppLoading />}
      <ReduxToastr
        timeOut={4000}
        newestOnTop={false}
        preventDuplicates
        position="top-right"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
      />
    </div>
  );
};

export default App;
