import {
  ADD_CARD_SUCCESS,
  FETCH_CARDS_SUCCESS,
  MAKE_DEFAULT_REQUEST,
  MAKE_DEFAULT_SUCCESS,
  MAKE_DEFAULT_FAILURE,
  DELETE_CARD_REQUEST,
  DELETE_CARD_SUCCESS,
  DELETE_CARD_FAILURE
} from "../types";

export const addCardSuccess = payload => ({
  type: ADD_CARD_SUCCESS,
  payload
});

export const fetchCardsSuccess = payload => ({
  type: FETCH_CARDS_SUCCESS,
  payload
});

export const makeDefaultRequest = payload => ({
  type: MAKE_DEFAULT_REQUEST,
  payload
});

export const makeDefaultSuccess = payload => ({
  type: MAKE_DEFAULT_SUCCESS,
  payload
});

export const makeDefaultFailure = payload => ({
  type: MAKE_DEFAULT_FAILURE,
  payload
});

export const deleteCardRequest = payload => ({
  type: DELETE_CARD_REQUEST,
  payload
});

export const deleteCardSuccess = payload => ({
  type: DELETE_CARD_SUCCESS,
  payload
});

export const deleteCardFailure = payload => ({
  type: DELETE_CARD_FAILURE,
  payload
});
