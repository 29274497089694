import { FETCH_CURRENT_USER_SUCCESS, LOGOUT, UPDATE_USER } from "../types";

export default function user(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_CURRENT_USER_SUCCESS:
      return action.payload.entities.users[action.payload.result];
    case UPDATE_USER:
      return { ...state, ...action.payload };
    case LOGOUT:
      return {};
    default:
      return state;
  }
}
