import { LOGOUT, LOAD_ADMIN_SUCCESS } from "../../types";

export default function settings(state = {}, action = {}) {
  switch (action.type) {
    case LOAD_ADMIN_SUCCESS:
      return action.payload.settings || {};
    case LOGOUT:
      return {};
    default:
      return state;
  }
}
