import React from "react";
import PropTypes from "prop-types";
import UsaTopupForm from "../forms/UsaTopupForm";
import ConfirmTransaction from "./ConfirmTransaction";
import { withTranslation } from "react-i18next";

class UsaTopupSection extends React.Component {
  state = {
    section: "form",
    transaction: null,
  };

  newTransaction = (transaction) =>
    this.setState({
      section: "confirm",
      transaction,
    });

  render() {
    return (
      <div>
        <h3 className="card-title">
          {this.state.section === "confirm" && (
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ section: "form" });
              }}
            >
              <i className="far fa-arrow-alt-circle-left" />
            </a>
          )}{" "}
          {this.props.t("headers.usaTopup")}
        </h3>
        <hr />
        {this.state.section === "form" && (
          <UsaTopupForm submit={this.newTransaction} />
        )}
        {this.state.section === "confirm" && (
          <ConfirmTransaction
            transaction={this.state.transaction}
            onSuccess={() =>
              this.setState({ section: "form", transaction: null })
            }
          />
        )}
      </div>
    );
  }
}

UsaTopupSection.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation("translations")(UsaTopupSection);
