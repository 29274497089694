import {
  FETCH_APP_TRANSACTIONS_REQUEST,
  FETCH_APP_TRANSACTIONS_SUCCESS,
  FETCH_APP_TRANSACTIONS_FAILURE
} from "../types";

export const fetchAppTransactionsRequest = payload => ({
  type: FETCH_APP_TRANSACTIONS_REQUEST,
  payload
});

export const fetchAppTransactionsSuccess = payload => ({
  type: FETCH_APP_TRANSACTIONS_SUCCESS,
  payload
});

export const fetchAppTransactionsFailure = payload => ({
  type: FETCH_APP_TRANSACTIONS_FAILURE,
  payload
});
