import {
  INITIAL_APP_LOAD_REQUEST,
  INITIAL_APP_LOAD_SUCCESS,
  INITIAL_APP_LOAD_FAILURE,
  SET_LANGUAGE
} from "../types";

export const initialAppLoadRequest = () => ({
  type: INITIAL_APP_LOAD_REQUEST
});

export const initialAppLoadSuccess = payload => ({
  type: INITIAL_APP_LOAD_SUCCESS,
  payload
});

export const initialAppLoadFailure = () => ({
  type: INITIAL_APP_LOAD_FAILURE
});

export const setLanguage = payload => ({
  type: SET_LANGUAGE,
  payload
});
