import size from "lodash/size";
import trim from "lodash/trim";
import find from "lodash/find";
import words from "lodash/words";
import startsWith from "lodash/startsWith";
import { makeRequest } from "./request";

export const formatTigoNumber = (number, countryid) => {
  if (!number || size(number) < 8 || !countryid) {
    return number || "";
  }

  let prefix = "";
  if (countryid === "756") {
    prefix = "502";
  }
  if (countryid === "762") {
    prefix = "504";
  }
  if (countryid === "730") {
    prefix = "503";
  }

  const lastEightDigits = number.substr(-8);
  return `${prefix}${lastEightDigits}`;
};

export const getMsisdnValuesToSearch = (callpackage) => {
  let data = {};
  if (!callpackage.BrandedName) {
    return data;
  }

  const values = words(callpackage.BrandedName);
  if (values[0] && values[1]) {
    data = { countryid: values[0], operatorid: values[1] };
    return data;
  }

  return data;
};

export const isValidMsisdn = async (number, msisdnValuesToSearch) => {
  if (
    !number ||
    size(number) < 8 ||
    !msisdnValuesToSearch.countryid ||
    !msisdnValuesToSearch.operatorid
  ) {
    return {
      success: false,
      formattedNumber: number,
    };
  }
  try {
    const numberToSearch = formatTigoNumber(
      number,
      msisdnValuesToSearch.countryid
    );
    // Handle edge case numbers not passing msisdn
    if (startsWith(numberToSearch, "5024000")) {
      return {
        success: true,
        formattedNumber: numberToSearch,
      };
    }
    const res = await makeRequest("get", `/api/msisdn/${numberToSearch}`);

    if (
      res.msisdn &&
      res.msisdn.Countryid === msisdnValuesToSearch.countryid &&
      res.msisdn.Operatorid === msisdnValuesToSearch.operatorid
    ) {
      return {
        success: true,
        formattedNumber: numberToSearch,
      };
    }
    return {
      success: false,
      formattedNumber: number,
    };
  } catch (error) {
    return {
      success: false,
      formattedNumber: number,
    };
  }
};

export const sendOneClickSms = async (mobilephone, destination) => {
  try {
    const response = await makeRequest("get", `/api/oneClicks/${mobilephone}`);

    if (size(response.oneClicks) > 0) {
      const foundOneClick = find(response.oneClicks, (i) => {
        if (trim(i.destination) === trim(destination)) {
          return i;
        }
      });
      if (foundOneClick) {
        makeRequest("post", `/api/sms`, {
          data: {
            sms: {
              mobileNo: mobilephone,
              message: `SIN PIN Marca este numero ${
                trim(foundOneClick.accessnumber) || ""
              } y te conecta directo a ${
                trim(destination) || ""
              } Agregalo en tus contactos y conectate con 1CLICK`,
            },
          },
        });
        return true;
      }
    }

    const res = await makeRequest(
      "post",
      `/api/oneClicks/addOneClick/${mobilephone}/${destination}`
    );
    if (res.oneClick.accessnumber && res.oneClick.destination) {
      makeRequest("post", `/api/sms`, {
        data: {
          sms: {
            mobileNo: mobilephone,
            message: `SIN PIN Marca este numero ${
              res.oneClick.accessnumber || ""
            } y te conecta directo a ${
              res.oneClick.destination || ""
            } Agregalo en tus contactos y conectate con 1CLICK`,
          },
        },
      });
      return true;
    }

    return true;
  } catch (error) {
    console.log(error);
    return true;
  }
};
