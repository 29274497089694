import React from "react";
import { Link } from "react-router-dom";

const AdminNavbar = () => (
  <div className="card mt-4">
    <div className="card-body">
      <Link to="/admin">Manage Users</Link> |{" "}
      <Link to="/admin/countries">Manage Countries</Link> |{" "}
      <Link to="/admin/notifications">Push Notifications</Link> |{" "}
      <Link to="/admin/blocklists">Block Lists</Link> |{" "}
      <Link to="/admin/phoneintelligence">Phone Intelligence</Link> |{" "}
      <Link to="/admin/searchdeviceids">Search by Device ID</Link> |{" "}
      <Link to="/admin/duplicatedeviceids">Duplicate Device IDs</Link>
    </div>
  </div>
);

export default AdminNavbar;
