import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { cardsCollectionSelector } from "../../selectors";
import TopupsCard from "../cards/TopupsCard";
import Promotions from "../elements/Promotions";
import BalanceBlock from "../elements/BalanceBlock";
import { withTranslation } from "react-i18next";

const DashboardPage = (props) => (
  <div className="container-fluid mt-3">
    <div className="container">
      <Promotions />

      <div className="row">
        <div className="col-md-4">
          <BalanceBlock
            balance={props.user.balance}
            title={props.t("headers.balance")}
            cardsLink={props.t("links.cards")}
            hasCards={props.cards.length > 0}
          />
        </div>
        <div className="col-md-8">
          <TopupsCard />
        </div>
      </div>
    </div>
  </div>
);

DashboardPage.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.object).isRequired,
  t: PropTypes.func.isRequired,
  user: PropTypes.shape({ balance: PropTypes.number.isRequired }).isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.user,
    cards: cardsCollectionSelector(state),
  };
}

export default connect(mapStateToProps)(
  withTranslation("translations")(DashboardPage)
);
