import React from "react";
import { makeRequest } from "../../utils/request";
import size from "lodash/size";
import map from "lodash/map";
import AdminNavbar from "./AdminNavbar";
import FormButton from "../elements/FormButton";

class AdminSearchDeviceIdsPage extends React.Component {
  state = {
    deviceId: "",
    results: {},
    errors: {},
    loading: false,
  };

  onSubmit = async (e) => {
    const { deviceId } = this.state;
    e.preventDefault();
    if (this.validate()) {
      try {
        this.setState({ loading: true, errors: {} });
        const res = await makeRequest(
          "get",
          `/api/admin/users/users_by_deviceid/${deviceId}`
        );
        this.setState({ results: res.users, loading: false });
      } catch (error) {
        this.setState({
          loading: false,
          errors: { deviceId: "Error Submitting Form" },
        });
      }
    }
  };

  validate = () => {
    const errors = {};

    if (!this.state.deviceId) errors.deviceId = "Invalid Device ID";
    this.setState({ errors });
    return Object.values(errors).length === 0;
  };

  render() {
    const { results } = this.state;
    return (
      <div className="container-fluid">
        <AdminNavbar />
        <div className="row mt-5">
          <div className="col col-lg-8 offset-lg-2 col-10 offset-1">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title">Search Users by Device ID</h3>
                <hr />
                <form onSubmit={this.onSubmit}>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label htmlFor="deviceId">Device ID</label>
                          <input
                            type="text"
                            id="deviceId"
                            name="deviceId"
                            className={
                              this.state.errors.deviceId
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            onChange={(e) =>
                              this.setState({ deviceId: e.target.value })
                            }
                            value={this.state.deviceId}
                            disabled={this.state.loading}
                          />
                          <div className="invalid-feedback form-text">
                            {this.state.errors.deviceId}
                          </div>
                        </div>
                      </div>
                    </div>
                    <FormButton
                      id="submit"
                      label="Submit"
                      loading={this.state.loading}
                    />
                  </div>
                </form>
                {size(this.state.results) > 0 ? (
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Phone</th>
                        <th>Device ID</th>
                      </tr>
                    </thead>
                    <tbody>
                      {map(this.state.results, (r) => {
                        return (
                          <tr key={r._id}>
                            <td>{r.phone}</td>
                            <td>{r.deviceId}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AdminSearchDeviceIdsPage;
