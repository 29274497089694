import React from "react";
import PropTypes from "prop-types";
import Button from "../elements/Button";

class TransactionRow extends React.Component {
  state = {
    selectedAmount: ""
  };

  render() {
    return (
      <tr>
        <td>
          {this.props.transaction.InternationalNumber}
          <br />
          <small className="text-muted">
            Country: {this.props.transaction.Country}
            <br />
            Carrier: {this.props.transaction.Carrier}
          </small>
        </td>
        <td>
          <select
            value={this.state.selectedAmount}
            onChange={e => this.setState({ selectedAmount: e.target.value })}
          >
            <option value="">Choose Amount</option>
            {this.props.transaction.Amounts.map(amount => (
              <option key={amount} value={amount}>
                $&nbsp;{parseFloat(amount).toFixed(2)}
              </option>
            ))}
          </select>
          <div className="d-block d-md-none">
            <br />
            <div className="d-flex justify-content-center">
              <Button
                color="teal"
                label="Repeat"
                disabled={!this.state.selectedAmount}
                onClick={() =>
                  this.props.repeat({
                    ...this.props.transaction,
                    Amount: parseFloat(this.state.selectedAmount)
                  })
                }
              />
            </div>
          </div>
        </td>
        <td className="d-none d-md-block">
          <Button
            color="teal"
            label="Repeat"
            disabled={!this.state.selectedAmount}
            onClick={() =>
              this.props.repeat({
                ...this.props.transaction,
                Amount: parseFloat(this.state.selectedAmount)
              })
            }
          />
        </td>
      </tr>
    );
  }
}

TransactionRow.propTypes = {
  transaction: PropTypes.shape({
    TigoOrderID: PropTypes.number.isRequired,
    Country: PropTypes.string.isRequired,
    Carrier: PropTypes.string.isRequired,
    Amount: PropTypes.number.isRequired,
    InternationalNumber: PropTypes.string.isRequired,
    Amounts: PropTypes.arrayOf(PropTypes.string).isRequired,
    Fee: PropTypes.number.isRequired
  }).isRequired,
  repeat: PropTypes.func.isRequired
};

export default TransactionRow;
