import {
  LOAD_ADMIN_REQUEST,
  LOAD_ADMIN_SUCCESS,
  LOAD_ADMIN_FAILURE,
  UPDATE_SETTINGS_SUCCESS,
  UPDATE_COUNTRY,
  ADMIN_UPDATED_USER,
  ADMIN_USER_PUSH_ERROR
} from "../types";

export const loadAdminRequest = payload => ({
  type: LOAD_ADMIN_REQUEST,
  payload
});

export const loadAdminSuccess = payload => ({
  type: LOAD_ADMIN_SUCCESS,
  payload
});

export const loadAdminFailure = payload => ({
  type: LOAD_ADMIN_FAILURE,
  payload
});

export const updateSettingsSuccess = payload => ({
  type: UPDATE_SETTINGS_SUCCESS,
  payload
});

export const updateCountry = payload => ({
  type: UPDATE_COUNTRY,
  payload
});

export const updateUser = payload => ({
  type: ADMIN_UPDATED_USER,
  payload
});

export const adminUserPushError = payload => ({
  type: ADMIN_USER_PUSH_ERROR,
  payload
});
