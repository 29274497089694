import { LOGOUT, DELETE_CARD_REQUEST, DELETE_CARD_SUCCESS } from "../types";

const initialState = {
  deleteCardInProgress: false
};

export default function ui(state = initialState, action = {}) {
  switch (action.type) {
    case DELETE_CARD_REQUEST:
      return {
        ...initialState,
        deleteCardInProgress: true
      };
    case DELETE_CARD_SUCCESS:
      return {
        ...initialState,
        deleteCardInProgress: false
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
