import React from "react";
import PropTypes from "prop-types";
import PageTitle from "../layout/PageTitle";
import LoginForm from "../forms/LoginForm";
import { withTranslation } from "react-i18next";

const LoginPage = (props) => (
  <div>
    <PageTitle title={props.t("headers.loginPage")} />

    <div className="row">
      <div className="col col-lg-8 offset-lg-2 col-10 offset-1">
        <div className="card">
          <div className="card-body">
            <LoginForm />
          </div>
        </div>
      </div>
    </div>
  </div>
);

LoginPage.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation("translations")(LoginPage);
