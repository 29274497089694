import {
  FETCH_COUNTRIES_SUCCESS,
  FETCH_CARRIERS_SUCCESS,
  FETCH_PRODUCTS_SUCCESS
} from "../types";

export const fetchCountriesSuccess = payload => ({
  type: FETCH_COUNTRIES_SUCCESS,
  payload
});

export const fetchCarriersSuccess = payload => ({
  type: FETCH_CARRIERS_SUCCESS,
  payload
});

export const fetchProductsSuccess = payload => ({
  type: FETCH_PRODUCTS_SUCCESS,
  payload
});
