import React from "react";
import { makeRequest } from "../../utils/request";
import size from "lodash/size";
import map from "lodash/map";
import moment from "moment";
import AdminNavbar from "./AdminNavbar";

class AdminDuplicateDeviceIdsPage extends React.Component {
  state = {
    results: {},
    errors: {},
    loading: false,
  };

  async componentDidMount() {
    try {
      this.setState({ loading: true, errors: {} });
      const res = await makeRequest(
        "get",
        `/api/admin/users/duplicate_devices/duplicates`
      );
      this.setState({ results: res.duplicates, loading: false });
    } catch (error) {
      this.setState({
        loading: false,
        errors: { deviceId: "Error Submitting Form" },
      });
    }
  }

  render() {
    const { results } = this.state;
    return (
      <div className="container-fluid">
        <AdminNavbar />
        <div className="row mt-5">
          <div className="col col-lg-8 offset-lg-2 col-10 offset-1">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title">{`Duplicate Device IDs: ${size(
                  this.state.results
                )} Records`}</h3>
                <hr />
                {size(this.state.results) > 0 ? (
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Phone</th>
                        <th>Device ID</th>
                        <th>Created At</th>
                      </tr>
                    </thead>
                    <tbody>
                      {map(this.state.results, (r) => {
                        return (
                          <tr key={r._id}>
                            <td>{r.phone}</td>
                            <td>{r.deviceId}</td>
                            <td>{moment(r.createdAt).format("ll")}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AdminDuplicateDeviceIdsPage;
