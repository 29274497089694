export default {
  textColor: "#212529",
  header: "#000",
  hover: "#ff0000",
  emphasis: "#f4645a",
  topNavBackground: "#000",
  teal: "#88d2db",
  darkTeal: "#7ebcc3",
  muted: "#6c757d",
  border: "#e4e4e4"
};
