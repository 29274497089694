import React from "react";
import PropTypes from "prop-types";

const PageTitle = props => (
  <div
    style={{
      backgroundColor: "white",
      padding: "20px 20px",
      marginBottom: "20px"
    }}
  >
    <h4
      className="text-center"
      style={{
        fontSize: "18px",
        fontWeight: 300
      }}
    >
      {props.title}
    </h4>
  </div>
);

PageTitle.propTypes = {
  title: PropTypes.string.isRequired
};

export default PageTitle;
