export const INITIAL_APP_LOAD_REQUEST = "INITIAL_APP_LOAD_REQUEST";
export const INITIAL_APP_LOAD_SUCCESS = "INITIAL_APP_LOAD_SUCCESS";
export const INITIAL_APP_LOAD_FAILURE = "INITIAL_APP_LOAD_FAILURE";
export const SET_LANGUAGE = "SET_LANGUAGE";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT = "LOGOUT";

export const UPDATE_USER = "UPDATE_USER";

export const ADD_CARD_SUCCESS = "ADD_CARD_SUCCESS";
export const FETCH_CARDS_SUCCESS = "FETCH_CARDS_SUCCESS";

export const MAKE_DEFAULT_REQUEST = "MAKE_DEFAULT_REQUEST";
export const MAKE_DEFAULT_SUCCESS = "MAKE_DEFAULT_SUCCESS";
export const MAKE_DEFAULT_FAILURE = "MAKE_DEFAULT_FAILURE";

export const DELETE_CARD_REQUEST = "DELETE_CARD_REQUEST";
export const DELETE_CARD_SUCCESS = "DELETE_CARD_SUCCESS";
export const DELETE_CARD_FAILURE = "DELETE_CARD_FAILURE";

export const FETCH_CURRENT_USER_SUCCESS = "FETCH_CURRENT_USER_SUCCESS";
export const FETCH_COUNTRIES_SUCCESS = "FETCH_COUNTRIES_SUCCESS";
export const FETCH_CARRIERS_SUCCESS = "FETCH_CARRIERS_SUCCESS";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";

export const LOAD_ADMIN_REQUEST = "LOAD_ADMIN_REQUEST";
export const LOAD_ADMIN_SUCCESS = "LOAD_ADMIN_SUCCESS";
export const LOAD_ADMIN_FAILURE = "LOAD_ADMIN_FAILURE";
export const UPDATE_SETTINGS_SUCCESS = "UPDATE_SETTINGS_SUCCESS";

export const FETCH_APP_TRANSACTIONS_REQUEST = "FETCH_APP_TRANSACTIONS_REQUEST";
export const FETCH_APP_TRANSACTIONS_SUCCESS = "FETCH_APP_TRANSACTIONS_SUCCESS";
export const FETCH_APP_TRANSACTIONS_FAILURE = "FETCH_APP_TRANSACTIONS_FAILURE";

export const UPDATE_COUNTRY = "UPDATE_COUNTRY";

export const FETCH_CALL_PACKAGES = "FETCH_CALL_PACKAGES";
export const ADMIN_UPDATED_USER = "ADMIN_UPDATED_USER";
export const ADMIN_USER_PUSH_ERROR = "ADMIN_USER_PUSH_ERROR";
