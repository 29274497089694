import {
  ADD_CARD_SUCCESS,
  FETCH_CARDS_SUCCESS,
  MAKE_DEFAULT_SUCCESS,
  DELETE_CARD_SUCCESS,
  LOGOUT,
  FETCH_CURRENT_USER_SUCCESS
} from "../types";

export default function cards(state = {}, action = {}) {
  switch (action.type) {
    case ADD_CARD_SUCCESS:
    case FETCH_CARDS_SUCCESS:
    case MAKE_DEFAULT_SUCCESS:
      return { ...state, ...action.payload.entities.cards };
    case DELETE_CARD_SUCCESS:
      return action.payload.entities.cards || {};
    case FETCH_CURRENT_USER_SUCCESS:
      return action.payload.entities.cards || {};
    case LOGOUT:
      return {};
    default:
      return state;
  }
}
