import React, { Component } from "react";
import { connect } from "react-redux";
import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone";
import FormButton from "../elements/FormButton";
import { withTranslation } from "react-i18next";
import { makeRequest } from "../../utils/request";

class RestorePasswordForm extends Component {
  state = {
    email: "",
    phone: "",
    loading: false,
    errors: {},
    showMessage: false,
  };

  componentDidMount() {
    this.phone.focus();
  }

  onChangeString = (e) => this.setState({ [e.target.name]: e.target.value });

  onSubmitEmail = async (e) => {
    e.preventDefault();
    if (!isEmail(this.state.email))
      this.setState({ errors: { email: "errors.email" } });

    try {
      this.setState({ loading: true });
      await makeRequest("post", `/api/auth/send_reset_link`, {
        email: this.state.email,
      });
      this.setState({ loading: false, showMessage: true });
    } catch (err) {
      this.setState({ errors: err.errors, loading: false });
    }
  };

  onSubmitPhone = async (e) => {
    e.preventDefault();
    if (!isMobilePhone(this.state.phone, "en-US")) {
      return this.setState({ errors: { phone: "errors.phone" } });
    }

    try {
      this.setState({ loading: true });
      await makeRequest(
        "post",
        `/api/auth/send_sms_reset_link/${this.state.phone}`
      );
      this.setState({ loading: false, showMessage: true });
    } catch (err) {
      this.setState({ errors: err.errors, loading: false });
    }
  };

  renderEmailForm = () => {
    const { t } = this.props;
    return (
      <form onSubmit={this.onSubmitEmail}>
        <div className="form-group">
          <label htmlFor="code">{t("loginForm.restorePassword")}</label>
          <input
            type="email"
            id="email"
            name="email"
            ref={(input) => (this.email = input)}
            className={
              this.state.errors.email
                ? "form-control is-invalid"
                : "form-control"
            }
            onChange={this.onChangeString}
            value={this.state.email}
            disabled={this.state.loading}
          />
          <div className="invalid-feedback form-text">
            {t(this.state.errors.email)}
          </div>
        </div>

        <FormButton
          label={t("buttons.sendResetLink")}
          loading={this.state.loading}
        />
      </form>
    );
  };

  renderPhoneForm = () => {
    const { t, lang } = this.props;
    let labelMsg = "Enter Your Mobile Number";
    if (lang === "es") {
      labelMsg = "Ingrese Su Número Móvil";
    }
    return (
      <form onSubmit={this.onSubmitPhone}>
        <div className="form-group">
          <label htmlFor="code">{labelMsg}</label>
          <input
            ref={(input) => (this.phone = input)}
            type="tel"
            id="phone"
            name="phone"
            maxLength={10}
            className={
              this.state.errors.phone
                ? "form-control is-invalid"
                : "form-control"
            }
            value={this.state.phone}
            onChange={this.onChangeString}
            disabled={this.state.loading}
          />
          <div className="invalid-feedback form-text">
            {t(this.state.errors.phone)}
          </div>
        </div>

        <FormButton
          label={t("buttons.sendResetLink")}
          loading={this.state.loading}
        />
      </form>
    );
  };

  render() {
    const { lang } = this.props;
    let sentMsg =
      "Success! Please check the text message to reset your password";
    if (lang === "es") {
      sentMsg =
        "¡Éxito! Por favor revise el mensaje de texto para restablecer su contraseña";
    }
    return (
      <div>
        {!this.state.showMessage && this.renderPhoneForm()}

        {this.state.showMessage && (
          <div className="alert alert-info">{sentMsg}</div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    lang: state.app.lang,
  };
}

export default connect(mapStateToProps)(
  withTranslation("translations")(RestorePasswordForm)
);
