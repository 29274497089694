import React from "react";
import _ from "lodash";
import Spinner from "react-spinkit";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { toastr } from "react-redux-toastr";
import Money from "../elements/Money";
import api from "../../api";
import ChargeCardWrapper from "../cards/ChargeCardWrapper";
import { withTranslation } from "react-i18next";
import {
  getMsisdnValuesToSearch,
  isValidMsisdn,
  sendOneClickSms,
} from "../../utils/callpackages";

class CallPackagesSection extends React.Component {
  state = {
    confirm: false,
    chosenPackage: {},
    errors: {},
    loading: false,
    card:
      this.props.cards.length > 0
        ? _.find(this.props.cards, { isDefault: true })._id
        : null,
    cvv: "",
    internationalNumber: "",
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.cards.length > 0 && this.props.cards.length === 0) {
      this.setState({
        ...this.state.data,
        card: _.find(nextProps.cards, { isDefault: true })._id,
      });
    }
  }

  prevent = (e) => {
    e.preventDefault();
  };

  buyPackage = async (packageId) => {
    const { chosenPackage, internationalNumber } = this.state;
    try {
      this.setState({ loading: true });
      if (chosenPackage.RequiresIntlNumber) {
        if (!internationalNumber || _.size(internationalNumber) < 8) {
          return this.setState({
            loading: false,
            errors: {
              internationalNumber: "Invalid Number for selected package",
            },
          });
        }
        const msisdnValid = await this.validateMsisdn(internationalNumber);
        if (!msisdnValid) {
          return this.setState({
            loading: false,
            errors: {
              internationalNumber: "Invalid Number for selected package",
            },
          });
        }
        sendOneClickSms(this.props.phone, internationalNumber);
      }

      await api.callPackages.purchase(
        packageId,
        this.state.card,
        this.state.internationalNumber
      );
      toastr.success(
        this.props.t("toastr.callPackageSuccess.title"),
        this.props.t("toastr.callPackageSuccess.content")
      );
      this.setState({ confirm: false, loading: false });
    } catch (err) {
      toastr.error(
        this.props.t("toastr.callPackageError.title"),
        this.props.t(err.errors.global)
      );
      this.setState({ confirm: false, loading: false });
    }
  };

  validateMsisdn = async (internationalNumber) => {
    const { chosenPackage } = this.state;
    try {
      const msisdnValuesToSearch = getMsisdnValuesToSearch(chosenPackage);
      if (!msisdnValuesToSearch.countryid || !msisdnValuesToSearch.operatorid) {
        return false;
      }

      const isMsisdnValid = await isValidMsisdn(
        internationalNumber,
        msisdnValuesToSearch
      );
      if (!isMsisdnValid.success) {
        return false;
      }

      this.setState(
        { internationalNumber: isMsisdnValid.formattedNumber },
        () => {
          return true;
        }
      );
      return true;
    } catch (error) {
      return false;
    }
  };

  hasCvv = () => {
    if (this.props.cards.length > 0) {
      const card = _.find(this.props.cards, { _id: this.state.card });
      return card && card.hasCvv;
    }
    return false;
  };

  hasCards = () => this.props.cards.length > 0;

  renderIntlInput = () => {
    return (
      <form onSubmit={this.prevent} noValidate>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="internationalNumber">
                {this.props.t("labels.internationalNumber")}
              </label>
              <div className="input-group input-group-lg">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fas fa-phone" />
                  </span>
                </div>
                <input
                  type="tel"
                  id="internationalNumber"
                  name="internationalNumber"
                  placeholder="country code + number"
                  ref={(input) => (this.phone = input)}
                  className={
                    this.state.errors.internationalNumber
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  onChange={(e) =>
                    this.setState({
                      internationalNumber: e.target.value,
                      errors: {},
                    })
                  }
                  value={this.state.internationalNumber}
                  disabled={this.state.loading}
                />
                <div className="invalid-feedback">
                  {this.props.t(this.state.errors.internationalNumber)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  };

  render() {
    const { errors, loading } = this.state;
    const { t } = this.props;
    const sortedPackages = _.sortBy(this.props.packages, ["Name"]);
    return (
      <div>
        <h3 className="card-title">{this.props.t("links.callPackages")}</h3>

        <table className="table">
          <tbody>
            {sortedPackages.map((pack) => (
              <tr key={pack.ID}>
                <td>{pack.Name}</td>
                <td>
                  <Money amount={pack.Price} />
                </td>
                <td>
                  <button
                    onClick={() =>
                      this.setState({ chosenPackage: pack, confirm: true })
                    }
                    className="btn btn-primary btn-block"
                  >
                    Buy for <Money amount={pack.Price} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <Modal isOpen={this.state.confirm} size="lg">
          <ModalHeader
            toggle={() =>
              this.setState({
                confirm: false,
                internationalNumber: "",
                errors: {},
              })
            }
          >
            Confirm Purchase
          </ModalHeader>
          <ModalBody>
            <p>{this.state.chosenPackage.Name}</p>
            {this.state.chosenPackage.RequiresIntlNumber
              ? this.renderIntlInput()
              : null}
            {this.hasCards() && (
              <div className="row">
                <div className="col">
                  {" "}
                  <div className="form-group">
                    <label htmlFor="card">{t("topupForm.card")}</label>
                    <select
                      id="card"
                      name="card"
                      className={
                        errors.card ? "form-control is-invalid" : "form-control"
                      }
                      onChange={(e) =>
                        this.setState({ card: e.target.value, cvv: "" })
                      }
                      value={this.state.card}
                      disabled={loading}
                    >
                      {this.props.cards.map((card) => (
                        <option key={card._id} value={card._id}>
                          **** {card.lastFour}
                        </option>
                      ))}
                    </select>
                    <div className="invalid-feedback form-text">
                      {t(errors.card)}
                    </div>
                  </div>
                </div>
                {!this.hasCvv() && (
                  <div className="col">
                    {" "}
                    <div className="form-group">
                      <label htmlFor="cvv">{t("cardForm.cvv")}</label>
                      <input
                        type="password"
                        id="cvv"
                        name="cvv"
                        className={
                          errors.cvv
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        onChange={(e) => this.setState({ cvv: e.target.value })}
                        value={this.state.cvv}
                        disabled={loading}
                      />
                      <div className="invalid-feedback form-text">
                        {t(errors.cvv)}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

            {this.hasCards() && (
              <button
                onClick={() => this.buyPackage(this.state.chosenPackage.ID)}
                className="btn btn-primary btn-block"
                disabled={this.state.loading}
              >
                {this.state.loading ? (
                  <Spinner name="circle" color="white" fadeIn="none" />
                ) : (
                  <span>
                    Buy for{" "}
                    <Money amount={this.state.chosenPackage.Price || 0} />
                  </span>
                )}
              </button>
            )}

            {!this.hasCards() && (
              <ChargeCardWrapper
                additionalData={{ callpackageId: this.state.chosenPackage.ID }}
                topup="package"
                success={() =>
                  this.setState({ confirm: false, loading: false })
                }
              />
            )}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

CallPackagesSection.propTypes = {
  t: PropTypes.func.isRequired,
  packages: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.string.isRequired,
      Name: PropTypes.string.isRequired,
      Price: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,
  cards: PropTypes.arrayOf(PropTypes.object).isRequired,
  phone: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    packages: state.callPackages,
    cards: Object.values(state.cards),
    phone: state.user.phone,
  };
}

export default connect(mapStateToProps)(
  withTranslation("translations")(CallPackagesSection)
);
