import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { setLanguage } from "../../actions/app";
import logo from "../../images/sinpin-logo-white.png";
import { withTranslation } from "react-i18next";

const Footer = (props) => (
  <div
    className="mt-5 container"
    style={{
      borderTop: "1px solid #e4e4e4",
      padding: "50px 0px",
    }}
  >
    <div className="row">
      <div className="col-md-4">
        <img src={logo} alt="Sin Pin Logo" className="img-fluid" />
        <p className="text-center">
          <small>{props.t("labels.copyright")}</small>
        </p>
      </div>
      <div className="col-md-2" />
      <div className="col-md-2" />
      <div className="col-md-2" />
      <div className="col-md-2 text-right align-middle">
        <i className="fas fa-phone" /> (877) 805-5665
      </div>
    </div>
  </div>
);

Footer.propTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(null, { setLanguage })(
  withTranslation("translations")(Footer)
);
