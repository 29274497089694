import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";
import { updateCountry } from "../../actions/admin";
import AdminNavbar from "./AdminNavbar";
import { makeRequest } from "../../utils/request";

class AdminPage extends React.Component {
  toggleActive = (country) => {
    makeRequest("put", `/api/admin/countries/${country._id}`, {
      country: { Active: !country.Active },
    }).then((res) => this.props.updateCountry(res.country));
  };

  render() {
    return (
      <div>
        <div className="container-fluid">
          <AdminNavbar />

          <div className="row mt-5">
            <div className="col col-lg-8 offset-lg-2 col-10 offset-1">
              <div className="card">
                <div className="card-body">
                  <h2 className="card-title">Countries</h2>
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>Country</th>
                        <th>Toggle Active</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.countries.map((country) => (
                        <tr key={country._id}>
                          <td>{country.Description}</td>
                          <td>
                            <button
                              className={`btn ${
                                country.Active ? "btn-success" : "btn-danger"
                              }`}
                              onClick={() => this.toggleActive(country)}
                            >
                              {country.Active ? "Active" : "Inactive"}
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    countries: _.orderBy(
      Object.values(state.countries),
      ["Description"],
      ["asc"]
    ),
  };
}

AdminPage.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default connect(mapStateToProps, { updateCountry })(AdminPage);
