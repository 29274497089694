import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Fade from "react-reveal/Fade";
import SinpinTopupForm from "../forms/SinpinTopupForm";
import UsaTopupSection from "./UsaTopupSection";
import CallPackagesSection from "./CallPackagesSection";
import InternationalTransactionsHistory from "./InternationalTransactionsHistory";
import {
  internationalTransactionsWithAmountsSelector,
  productsHashSelector,
} from "../../selectors";
import { withTranslation } from "react-i18next";

class TopupsCard extends Component {
  state = {
    tabIndex: 1,
  };

  render() {
    return (
      <div className="card mt-md-0 mt-5">
        <div className="card-header">
          <ul className="nav nav-tabs card-header-tabs">
            <li className="nav-item">
              <a
                href="/"
                className={
                  this.state.tabIndex === 1 ? "nav-link active" : "nav-link"
                }
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ tabIndex: 1 });
                }}
              >
                {this.props.t("links.sinpinTopup")}
              </a>
            </li>
            <li className="nav-item">
              <a
                href="/"
                className={
                  this.state.tabIndex === 2 ? "nav-link active" : "nav-link"
                }
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ tabIndex: 2 });
                }}
              >
                {this.props.t("links.internationalTopup")}
              </a>
            </li>
            <li className="nav-item">
              <a
                href="/"
                className={
                  this.state.tabIndex === 3 ? "nav-link active" : "nav-link"
                }
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ tabIndex: 3 });
                }}
              >
                {this.props.t("links.usaTopup")}
              </a>
            </li>
            <li className="nav-item">
              <a
                href="/"
                className={
                  this.state.tabIndex === 4 ? "nav-link active" : "nav-link"
                }
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ tabIndex: 4 });
                }}
              >
                {this.props.t("links.callPackages")}
              </a>
            </li>
          </ul>
        </div>
        <div className="card-body">
          {this.state.tabIndex === 1 && (
            <Fade>
              <SinpinTopupForm />
            </Fade>
          )}
          {this.state.tabIndex === 2 && (
            <Fade>
              <InternationalTransactionsHistory
                transactions={this.props.transactions}
                products={this.props.products}
                section={
                  this.props.transactions.length > 0 ? "history" : "form"
                }
              />
            </Fade>
          )}
          {this.state.tabIndex === 3 && (
            <Fade>
              <UsaTopupSection />
            </Fade>
          )}
          {this.state.tabIndex === 4 && (
            <Fade>
              <CallPackagesSection />
            </Fade>
          )}
        </div>
      </div>
    );
  }
}

TopupsCard.propTypes = {
  t: PropTypes.func.isRequired,
  transactions: PropTypes.arrayOf(PropTypes.object).isRequired,
  products: PropTypes.shape({}).isRequired,
};

function mapStateToProps(state) {
  return {
    transactions: internationalTransactionsWithAmountsSelector(state),
    products: productsHashSelector(state),
  };
}

export default connect(mapStateToProps)(
  withTranslation("translations")(TopupsCard)
);
