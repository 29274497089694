import React, { Component } from "react";
import PropTypes from "prop-types";
import FormButton from "../elements/FormButton";
import { withTranslation } from "react-i18next";
import { makeRequest } from "../../utils/request";

class NewPasswordForm extends Component {
  state = {
    password: "",
    passwordConfirmation: "",
    loading: false,
    errors: {},
    done: false,
  };

  componentDidMount() {
    this.password.focus();
  }

  onChangeString = (e) => this.setState({ [e.target.name]: e.target.value });

  onSubmit = async (e) => {
    e.preventDefault();
    if (this.validate()) {
      try {
        this.setState({ loading: true });
        await makeRequest("post", "/api/auth/reset_password", {
          password: this.state.password,
          passwordConfirmation: this.state.passwordConfirmation,
          token: this.props.token,
        });
        this.setState({ loading: false, done: true });
      } catch (err) {
        this.setState({ loading: false, errors: err.errors });
      }
    }
  };

  validate = () => {
    const errors = {};

    if (!this.state.password) errors.password = "errors.empty";
    if (!this.state.passwordConfirmation)
      errors.passwordConfirmation = "errors.empty";
    if (this.state.password !== this.state.passwordConfirmation)
      errors.passwordConfirmation = "errors.match";

    this.setState({ errors });
    return Object.keys(errors).length === 0;
  };

  render() {
    const { t } = this.props;
    const { errors } = this.state;

    return (
      <div>
        {!this.state.done && (
          <form onSubmit={this.onSubmit}>
            <div className="form-group">
              <label htmlFor="password">{t("loginForm.passwordLabel")}</label>
              <input
                type="password"
                id="password"
                name="password"
                ref={(input) => (this.password = input)}
                className={
                  errors.password ? "form-control is-invalid" : "form-control"
                }
                onChange={this.onChangeString}
                value={this.state.password}
                disabled={this.state.loading}
              />
              <div className="invalid-feedback form-text">
                {t(errors.password)}
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="password">
                {t("loginForm.passwordConfirmation")}
              </label>
              <input
                type="password"
                id="passwordConfirmation"
                name="passwordConfirmation"
                className={
                  errors.passwordConfirmation
                    ? "form-control is-invalid"
                    : "form-control"
                }
                onChange={this.onChangeString}
                value={this.state.passwordConfirmation}
                disabled={this.state.loading}
              />
              <div className="invalid-feedback form-text">
                {t(errors.passwordConfirmation)}
              </div>
            </div>

            <FormButton
              label={t("buttons.login")}
              loading={this.state.loading}
            />
          </form>
        )}

        {this.state.done && (
          <div className="alert alert-info">{t("messages.passwordReset")}</div>
        )}
      </div>
    );
  }
}

NewPasswordForm.propTypes = {
  t: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
};

export default withTranslation("translations")(NewPasswordForm);
