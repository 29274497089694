import React from "react";
import PropTypes from "prop-types";

const Money = props => (
  <span>$&nbsp;{parseFloat(props.amount).toFixed(2)}</span>
);

Money.propTypes = {
  amount: PropTypes.number.isRequired
};

export default Money;
