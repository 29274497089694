import { getToken, persistToken, removeToken } from "../utils/persist";
import { makeRequest } from "../utils/request";
import { normalize } from "normalizr";
import {
  cardSchema,
  carrierSchema,
  countrySchema,
  productSchema,
  userSchema,
} from "../schemas";
import { fetchCurrentUserSuccess } from "./users";
import { initialAppLoadSuccess, setLanguage } from "./app";
import { loadAdminSuccess } from "./admin";
import api from "../api";
import {
  fetchCarriersSuccess,
  fetchCountriesSuccess,
  fetchProductsSuccess,
} from "./products";
import { fetchCallPackages } from "./callPackages";
import sanityClient from "@sanity/client";
import {
  SANITY_DATASET,
  SANITY_PROJECT_ID,
  SANITY_QUERIES,
} from "../utils/constants";
import {
  deleteCardFailure,
  deleteCardSuccess,
  makeDefaultSuccess,
} from "./cards";
import { toastr } from "react-redux-toastr";
import i18n from "../i18n";

export const initialLoadThunk = () => async (dispatch) => {
  const token = getToken();

  if (token) {
    // fetch current user: serves also as user authorization
    try {
      const user = await api.users.fetchCurrentUser();
      dispatch(fetchCurrentUserSuccess(normalize(user, userSchema)));
    } catch (e) {
      console.log(e);
      return null;
    }

    // fetch all data from api
    const [countries, carriers, products, callPackages] = await Promise.all([
      api.countries.fetch(),
      api.carriers.fetch(),
      api.products.fetch(),
      api.callPackages.fetch(),
    ]);
    dispatch(fetchCountriesSuccess(normalize(countries, [countrySchema])));
    dispatch(fetchCarriersSuccess(normalize(carriers, [carrierSchema])));
    dispatch(fetchProductsSuccess(normalize(products, [productSchema])));
    dispatch(fetchCallPackages(callPackages));

    // fetch sanity announcements
    const client = sanityClient({
      projectId: SANITY_PROJECT_ID,
      dataset: SANITY_DATASET,
      useCdn: true,
    });
    try {
      const promos = await client.fetch(SANITY_QUERIES.announcements);
      dispatch({ type: "PROMOS_FETCHED", payload: promos });
    } catch (err) {
      console.log("couldn't fetch promos"); // eslint-disable-line
    }
  }

  dispatch(initialAppLoadSuccess());
  return null;
};

export const fetchAdminUsersThunk = () => async (dispatch) => {
  try {
    const { users } = await makeRequest("get", "/api/admin/users");
    dispatch(loadAdminSuccess(normalize(users, [userSchema])));
  } catch (e) {
    console.log(e);
  }
};

export const loggedInThunk = (token) => async (dispatch) => {
  persistToken(token);
  window.location = "/dashboard";
};

export const loggedOutThunk = () => async (dispatch) => {
  removeToken();
  window.location = "/";
};

// CARDS

export const setCardAsDefaultThunk = (cardId) => async (dispatch) => {
  const cards = await api.cards.makeDefault(cardId);
  dispatch(makeDefaultSuccess(normalize(cards, [cardSchema])));
};
export const deleteCardThunk = (cardId) => async (dispatch) => {
  const cards = await api.cards.delete(cardId);
  dispatch(deleteCardSuccess(normalize(cards, [cardSchema])));
  toastr.success(
    i18n.t("toastr.cardDeleted.title"),
    i18n.t("toastr.cardDeleted.content")
  );
};
