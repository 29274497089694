import { makeRequest } from "./utils/request";

export default {
  cards: {
    fetch: () => makeRequest("get", "/api/cards").then((res) => res.cards),
    makeDefault: (_id) =>
      makeRequest("put", `/api/cards/${_id}/makeDefault`).then(
        (res) => res.cards
      ),
    delete: (_id) =>
      makeRequest("delete", `/api/cards/${_id}`).then((res) => res.cards),
  },
  users: {
    fetchCurrentUser: () =>
      makeRequest("get", "/api/users/current_user").then((res) => res.user),
  },
  topups: {
    sinpinRecharge: (data) =>
      makeRequest("post", "/api/topups/sinpin", { data }),
  },
  countries: {
    fetch: () =>
      makeRequest("get", "/api/countries").then((res) => res.countries),
  },
  carriers: {
    fetch: () =>
      makeRequest("get", "/api/carriers").then((res) => res.carriers),
  },
  products: {
    fetch: () =>
      makeRequest("get", "/api/products").then((res) => res.products),
  },
  appTransactions: {
    fetch: () =>
      makeRequest("get", "/api/transactions").then(
        (res) => res.appTransactions
      ),
  },
  callPackages: {
    fetch: () =>
      makeRequest("get", "/api/callpackages").then((res) => res.callPackages),
    purchase: (id, card, InternationalNumber) =>
      makeRequest("post", `/api/topups/callpackage/${id}`, {
        data: { card, InternationalNumber },
      }),
  },
  sms: {
    post: (phone, message) =>
      makeRequest("post", `/api/sms`, {
        data: { sms: { mobileNo: phone, message: message } },
      }).then((res) => res.sms),
  },
  featureKeys: {
    fetch: (phone) =>
      makeRequest("get", `/api/featureKeys/${phone}`).then(
        (res) => res.featureKeys
      ),
    post: (featureKey) =>
      makeRequest("post", `/api/featureKeys`, {
        data: { featureKey },
      }),
    put: (featureKey) =>
      makeRequest("put", `/api/featureKeys`, {
        data: { featureKey },
      }),
  },
  admin: {
    users: {
      fetch: () =>
        makeRequest("get", "/api/admin/users").then((res) => res.users),
    },
    settings: {
      fetch: () =>
        makeRequest("get", "/api/admin/settings").then((res) => res.settings),
    },
  },
};
