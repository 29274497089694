import { FETCH_CURRENT_USER_SUCCESS, UPDATE_USER } from "../types";

export const fetchCurrentUserSuccess = payload => ({
  type: FETCH_CURRENT_USER_SUCCESS,
  payload
});

export const updateUser = payload => ({
  type: UPDATE_USER,
  payload
});
