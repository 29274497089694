import { FETCH_PRODUCTS_SUCCESS } from "../types";

export default function countries(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_PRODUCTS_SUCCESS:
      return { ...state, ...action.payload.entities.products };
    default:
      return state;
  }
}
