export const LS_NAME = "sinpinJWT";
export const BASE_API_URL = "http://locahost:8085";
export const SANITY_PROJECT_ID = "p3atdl8l";
export const SANITY_DATASET = "promos";

export const SANITY_QUERIES = {
  announcements: `
    *[_type == "announcement" && isActive == true && displayOnPayOnline == true]
    { shortDescription, fullDescription, "narrowImage": narrowImage.asset->url,
      "imageUrl": image.asset->url,
      "countries": countries[]->{name, "flagUrl": flag.asset->url}
    }
    `,
  assets: `
      *[_type == "assets"]
      { "image": mainPageImage.asset->url }
    `,
};
